import PropTypes from 'prop-types';

import { DataCardBottom } from 'components/afterworld/data/card/DataCardBottom';
import { DataCardContainer } from 'components/afterworld/data/card/DataCardContainer';
import { DataCardContent } from 'components/afterworld/data/card/DataCardContent';
import { DataCardDescription } from 'components/afterworld/data/card/DataCardDescription';
import { DataCardFrame } from 'components/afterworld/data/card/DataCardFrame';
import { DataCardTitle } from 'components/afterworld/data/card/DataCardTitle';

import { CardPin } from './CardPin';

export function DataCard({
  bottom = [],
  className = '',
  content = '',
  description = '',
  pinLeft = {},
  pinRight = {},
  title,
}) {
  return (
    <DataCardContainer className={className}>
      <DataCardFrame>
        <DataCardTitle>
          {!!pinLeft?.value && (<CardPin placement="left" {...pinLeft}>{pinLeft.value}</CardPin>)}
          {!!pinRight?.value && (<CardPin placement="right" {...pinRight}>{pinRight.value}</CardPin>)}

          {title}
        </DataCardTitle>

        {!!content && (
          <DataCardContent>
            {content}
          </DataCardContent>
        )}

        {!!description && (
          <DataCardDescription>
            {description}
          </DataCardDescription>
        )}

        {!!bottom.length && (
          <DataCardBottom>
            {bottom}
          </DataCardBottom>
        )}
      </DataCardFrame>
    </DataCardContainer>
  );
}

const config = {
  tooltip: PropTypes.string,
  value: PropTypes.node,
};

DataCard.propTypes = {
  bottom: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  content: PropTypes.node,
  description: PropTypes.node,
  pinLeft: PropTypes.shape(config),
  pinRight: PropTypes.shape(config),
  title: PropTypes.node.isRequired,
};

