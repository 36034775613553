import Fab from '@mui/material/Fab';
import { useCallback, useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import styles from './ScrollToTopButton.module.css';

export function ScrollToTopButton() {
  const [visible, setVisible] = useState(document.documentElement.scrollTop > 300);

  const toggleVisible = useCallback(
    () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300 && !visible) {
        setVisible(true);
      } else if (scrolled <= 300 && visible) {
        setVisible(false);
      }
    },
    [visible],
  );

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, [toggleVisible]);

  return (
    <Fab
      className={`${styles.button} ${visible ? styles.visible : styles.hidden}`}
      onClick={scrollToTop}
      size="small"
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
}
