import { useParams } from 'react-router-dom';

import { CharacterSkillsTable } from 'components/character/CharacterSkillsTable';
import { useStore } from 'store';

export function CharacterSkillsPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useStore((state) => state.characters[characterId]);

  return (
    <CharacterSkillsTable data={character.skills} />
  );
}
