import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";

import { Character } from 'lib/character';
import { Quote, List } from 'components/semantic';
import { PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';
import { useStore } from 'store';

export function CharactersListPage() {
  const navigate = useNavigate();
  const characters = useStore((state) => state.characters);
  const addCharacter = useStore((state) => state.addCharacter);

  const AddButton = (
    <Box onClick={() => addCharacter(new Character())} sx={{ cursor: 'pointer' }}>
      <FontAwesomeIcon icon={['fa', 'person-circle-plus']} />
    </Box>
  );

  const handleClick = (character) => {
    navigate(`/character/general/${character.id}`);
  };

  return (
    <PageRootContainer target="/">
      <PageHeading rightContent={AddButton}>
        Personnages
      </PageHeading>

      <Quote>
        ATTENTION!<br />
        Cette section est encore en phase de test : les données peuvent être perdues.
      </Quote>

      <List
        data={Object.values(characters)}
        displayKey="name"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      ></List>
    </PageRootContainer>
  );
}
