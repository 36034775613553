import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export function DataMenu() {
  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'hands-holding-circle']} />}
        to="/data/gifts"
      >
        Aptitudes
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'wand-magic-sparkles']} />}
        to="/data/spells"
      >
        Sorts
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'basket-shopping']} />}
        to="/data/items"
      >
        Objets
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'gun']} />}
        to="/data/weapons"
      >
        Armes
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'shield-halved']} />}
        to="/data/armors"
      >
        Armures
      </Button>
    </>
  );
}
