import beasts from './beasts';
import elementals from './elementals';
import humans from './humans';
import multi from './multi';
import mutants from './mutants';
import plants from './plants';
import robots from './robots';

const all = [
  ...beasts,
  ...elementals,
  ...humans,
  ...multi,
  ...mutants,
  ...plants,
  ...robots,
];

export default all;

export const Monsters = all;
