import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

import { PageHeading } from 'components/elements';
import { Text } from 'components/semantic';
import { PageRootContainer } from 'layout/PageRootContainer';

import packageJson from '../../package.json';
import styles from './HomePage.module.css';

export function HomePage() {
  return (
    <PageRootContainer>
      <PageHeading>
        Afterworld
      </PageHeading>

      <Box className={styles.container}>
        <Box className={styles.intro}>
          {`L'experience du jeu de role post-apocalyptique`}
        </Box>

        <List component="nav">
          <ListItemButton component={Link} to="/manual">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'book']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="Manuel" />
          </ListItemButton>

          <ListItemButton component={Link} to="/data/gifts">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'database']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="Données" />
          </ListItemButton>

          <ListItemButton component={Link} to="/bestiary">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'dragon']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="Bestiaire" />
          </ListItemButton>

          {/* <ListItemButton component={Link} to="/generators/character">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'industry']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="Générateurs" />
          </ListItemButton> */}

          <ListItemButton component={Link} to="/universe">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'sun-plant-wilt']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="L'univers" />
          </ListItemButton>

          <ListItemButton component={Link} to="/characters">
            <ListItemIcon>
              <FontAwesomeIcon icon={['fa', 'people-group']} className={styles.dropShadow} />
            </ListItemIcon>
            <ListItemText primary="Personnages" />
          </ListItemButton>
        </List>

        <Text>
          Pour revenir sur ce menu ensuite, faites un swipe vers la gauche!
        </Text>

        <Box className={styles.version}>
          {packageJson.version}
        </Box>
      </Box>
    </PageRootContainer>
  );
}
