export default (set) => ({
  characters: {},
  addCharacter: (character) => set((state) => ({ characters: { ...state.characters, [character.id]: character } })),
  editCharacter: (character) => set((state) => ({ characters: { ...state.characters, [character.id]: character } })),
  removeCharacter: (character) => set((state) => {
    const characters = { ...state.characters };
    delete characters[character.getId()];
    return { characters };
  }),
});
