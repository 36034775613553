import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

export function CharactersMenu() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'person-rays']} />}
        to={`/character/general/${characterId}`}
      >
        General
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'hand-fist']} />}
        to={`/character/gifts/${characterId}`}
      >
        Aptitudes
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'toolbox']} />}
        to={`/character/skills/${characterId}`}
      >
        Competences
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'hat-wizard']} />}
        to={`/character/magic/${characterId}`}
      >
        Magies
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'person-walking-luggage']} />}
        to={`/character/items/${characterId}`}
      >
        Inventaire
      </Button>
    </>
  );
}
