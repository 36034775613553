import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DataCard } from 'components/afterworld/data/card/DataCard';
import { CardPin } from 'components/afterworld/data/card/CardPin';

const Effect = styled.div`
  margin: 4px 0;
  padding: 4px 0;
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &:not(:first-of-type) {
    border-top: dotted 1px lightgray;
  }
`;

export function Item({
  effect = '',
  name,
  note = '',
  value = 0,
  weight,
}) {
  return (
    <DataCard
      content={effect.split('\n').map((e, i) => (<Effect key={i}>{e}</Effect>))}
      description={note}
      title={name}
      bottom={[
        <CardPin key={0} tooltip={`Valeur`}>
          <FontAwesomeIcon icon={['fa', 'sack-dollar']} />
          {' '}
          {value}
        </CardPin>,
        <CardPin key={1} tooltip={`Poids`}>
          <FontAwesomeIcon icon={['fa', 'weight-hanging']} />
          {' '}
          {weight}
        </CardPin>
      ]}
    />
  );
}

Item.propTypes = {
  effect: PropTypes.string,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  value: PropTypes.number,
  weight: PropTypes.number.isRequired,
};
