import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseLocationAfricaPage() {
  return (
    <>
      <PageHeading>
        {`L'Afrique`}
      </PageHeading>

      <Title>{`Paysage`}</Title>

      <Text>
        {`Sur le continent africain, les grandes villes, épargnées par les frappes directes des bombes, se dressent comme des spectres du passé. Bien qu’intactes en apparence, elles sont marquées par le passage du temps et les ravages de la nature. Les gratte-ciel autrefois étincelants sont maintenant recouverts de plantes grimpantes et de mousse, et des racines épaisses fendent les rues désertées, s'insinuant dans le béton et le bitume. Dans les campagnes, la végétation a repris ses droits de manière spectaculaire : des forêts denses et luxuriantes recouvrent les terres, et les plantes autrefois domestiquées se mêlent aux espèces sauvages dans un foisonnement de vie. Les déserts, eux, ont cédé une partie de leur empire sous l'effet d’un climat désormais plus frais, et des oasis inattendues apparaissent là où autrefois régnait la sécheresse.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/locations/africa-cairo.webp" />
        <Image size="large" src="/images/universe/locations/africa-cairo2.webp" />
        <Image size="large" src="/images/universe/locations/africa-dakar.webp" />
      </Gallery>

      <Title>{`Présence humaine`}</Title>

      <Text>
        {`La vie humaine persiste ici, mais elle est devenue plus discrète, plus rusée, pour survivre aux dangers du monde actuel. Les survivants forment rarement de grandes communautés organisées ; au lieu de cela, ils vivent dans de petits villages ou de modestes enclaves nichées dans les ruines des anciennes métropoles. Les rues vides des villes servent parfois d'abris, de refuges improvisés, où les hommes et les femmes se rassemblent en petites tribus, partageant les récits d'un passé révolu et les rumeurs des périls à venir. Dans les campagnes, ils construisent des villages fortifiés, faits de matériaux récupérés, formant une mosaïque de vie à la fois frugale et résiliente.`}
      </Text>

      <Title>{`Présences non humaines`}</Title>

      <Text>
        {`Les campagnes africaines abritent désormais bien plus que des survivants humains. Les bêtes sauvages qui peuplaient jadis le continent ont muté, transformées par les radiations et les épreuves de l'après-guerre. Ces créatures, aux formes étranges et aux instincts exacerbés, rôdent près des villes et des villages, souvent affamées et prêtes à attaquer. Pour les habitants, elles représentent une menace omniprésente, une menace que même les armes rudimentaires et les barricades ne suffisent pas toujours à contenir. Les plus téméraires osent parfois s'aventurer dans les terres sauvages, mais chacun sait que le moindre faux pas peut être fatal face à ces nouvelles bêtes qui règnent en maîtres dans l'ombre des ruines.`}
      </Text>
    </>
  );
}
