import { Outlet } from 'react-router-dom';

import { PageRootContainer } from 'layout/PageRootContainer';

export function SectionLayout() {
  return (
    <PageRootContainer target="/">
      <Outlet />
    </PageRootContainer>
  );
}
