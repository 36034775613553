export const EffectType = Object.freeze({
  Skill: 'Compétence',
  SkillCategory: 'Catégorie de compétence',
  Magic: 'Magie',
  Passive: 'Passif',
});

export const EffectOperation = Object.freeze({
  Additive: 'Ajout',
  Multiplicative: 'Facteur',
})

export class Effect {
  #origin;
  #description;

  constructor(origin, description) {
    this.#origin = origin;
    this.#description = description;
  }
}

export class TargetedEffect extends Effect {
  #target;
  #type;
  #value;
  #operation;

  constructor(origin, description, target, type, value, operation) {
    super(origin, description);
    this.#target = target;
    this.#type = type;
    this.#value = value;
    this.#operation = operation;

  }
}
