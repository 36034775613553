const aptitudes = [
  {
    name: `Afflux magique`,
    effect: [`Les scores de dé pair sur les sorts réduisent le coût en Magie de 1`],
  },
  {
    name: `Alcoolique renfrogné`,
    effect: [`Tant que le personnage est alcoolisé, le score du dé de blessures infligé est doublé`]
  },
  {
    name: `Apprenti sorcier`,
    effect: [`Nouvelle magie spécialisée`],
  },
  {
    name: `Beau parleur`,
    effect: [
      `Combat -5`,
      `Social +10`,
    ]
  },
  {
    name: `Bonne étoile`,
    effect: [`Chances de succès critique hors des combats +3%`],
  },
  {
    name: `Bretteur hors pair`,
    effect: [`Feinte gratuite et l'attaque après une feinte bénéficie désormais des mêmes bonus qu'une attaque furtive`],
  },
  {
    name: `Contorsionniste`,
    effect: [
      `Plus de limite d'esquive par tour`,
      `Possible d'esquiver même au sol`,
    ],
  },
  {
    name: `Dernier souffle`,
    effect: [
      `Il devient toujours possible d'effectuer des actions pleines même lors d'un état de fatigue`,
      `Se reposer en combat récupère désormais 2 points de fatigue`,
    ],
  },
  {
    name: `Dévotion scientifique`,
    effect: [
      `Combat -5`,
      `Savoirs +10`,
    ],
  },
  {
    name: `Frénétique`,
    effect: [`Ajoute un bonus au dé de blessures d'un montant équivalent au score de blessure le plus élevé sur le personnage`],
  },
  {
    name: `Gâchette facile`,
    effect: [
      `Sur la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT}, les attaques d'opportunité ne nécessitent plus d'être au contact`,
      `Chances de critiques +5% lors d'une attaque d'opportunité avec une arme légère`,
    ],
  },
  {
    name: `Maître des spécialités`,
    requirements: [`Ne pas avoir l'aptitude 'Touche à tout'`],
    effect: [
      `Nouvelle compétence de spécialité`,
      `Compétences de spécialité +5`,
    ],
  },
  {
    name: `Opportuniste`,
    effect: [
      `Les attaques d'opportunité sont gratuites et ne nécessitent plus de test d'Agilité`,
      `De plus, les chances de toucher sont augmentées de +10%`,
    ],
  },
  {
    name: `Précision d'un horloger`,
    effect: [
      `La détonation des explosifs a toujours lieu au contact de la cible`,
      `Les lancers d'explosifs ne peuvent plus être renvoyés`,
    ],
  },
  {
    name: `Regain de chance`,
    effect: [`Les succès critiques avec un dé à 01 rapportent un point de chance`],
  },
  {
    name: `Sanguinaire`,
    effect: [
      `Lors des attaques critiques, le score de blessures est doublé au lieu d'y ajouter le degré de réussite naturel`,
      `Les ennemis tués explosent en morceaux`,
    ],
  },
  {
    name: `Sens du combat`,
    effect: [
      `Les facteurs environnementaux comme la pluie ou la luminosité n'ont plus d'influence`,
      `Initiative +4`,
    ],
  },
  {
    name: `Sorcier autodidacte`,
    effect: [
      `Annule la pénalité d'incantation lorsqu'une canalisation est en cours`,
      `Esprit +5`,
    ],
  },
  {
    name: `Tireur d'élite`,
    effect: [
      `Avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} et une seule fois par tour, une des actions suivantes est gratuite.`,
      `Recharger | Engainer | Dégainer | Localiser`,
    ],
  },
  {
    name: `Touche à tout`,
    effect: [
      `Toutes les compétences +5`,
      `Compétences de spécialité -20`,
    ],
  },
]

export default aptitudes.map((a) => ({ ...a, level: 10 }));
