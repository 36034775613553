import { EffectOperation } from './effects';
import { Rollable } from './internal/Rollable';

export class Magic extends Rollable {

  #character;

  #name;
  get name() { return this.#name; }

  constructor(character, name) {
    super();
    this.#character = character;
    this.#name = name;
  }

  get value() {
    return this.spent + this.experience + (this.specialized ? 30 : 0) + this.modifiers;
  }

  get modifiers() {
    const relatedEffects = this.#character.effects.filter((e) => (e.type === Magic && e.target === this.#name));
    let mod = relatedEffects.filter((e) => (e.operation === EffectOperation.Additive)).reduce((total, v) => (total + v.value), 0);
    relatedEffects.filter((e) => (e.operation === EffectOperation.Multiplicative)).forEach((effect) => {
      mod *= effect.value;
    });
    return mod;
  }

  static createFullSet(effects) {
    return [
      new Magic(effects, `Eau et nature`),
      new Magic(effects, `Feu et terre`),
      new Magic(effects, `Vent et foudre`),
      new Magic(effects, `Lumière et vie`),
      new Magic(effects, `Ombre et mort`),
      new Magic(effects, `Esprit`),
      new Magic(effects, `Plans`),
      new Magic(effects, `Temps`),
    ];
  }

  serialize() {
    return {
      experience: this.experience,
      name: this.name,
      specialized: this.specialized,
      spent: this.spent,
    };
  }

  static deserialize(character, savedData) {
    const magic = new Magic(character, savedData.name);
    magic.experience = savedData.experience;
    magic.specialized = savedData.specialized;
    magic.spent = savedData.spent;
    return magic;
  }

}
