import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Character } from 'lib/character';

import charactersSlice from './slices/characters';
import filtersSlice from './slices/filters';
import settingsSlice from './slices/settings';

function replacer(key, value) {
  return value instanceof Character
    ? { ...value.serialize(), __class: 'Character' }
    : value;
}

function reviver(key, value) {
  return value && value.__class === 'Character'
    ? Character.deserialize(value)
    : value;
}

function withStorageDOMEvents(store) {
  const storageEventCallback = (event) => {
    if (event.key === store.persist.getOptions().name && event.newValue) {
      store.persist.rehydrate();
    }
  };

  window.addEventListener('storage', storageEventCallback);

  return () => {
    window.removeEventListener('storage', storageEventCallback)
  };
};

const store = create(
  persist(
    (set) => ({
      ...charactersSlice(set),
      ...filtersSlice(set),
      ...settingsSlice(set),
    }),
    {
      name: 'afterworld',
      storage: createJSONStorage(() => localStorage, {
        replacer,
        reviver,
      }),
    },
  ),
);

withStorageDOMEvents(store);

export const useStore = store;
