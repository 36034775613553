
import localization from 'data/rules/localization';
import precisionModifiers from 'data/rules/precisionModifiers';
import wounds from 'data/rules/wounds';
import { Quote, Subtitle, Table, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualDamagePage() {
  return (
    <>
      <PageHeading>
        Attaquer
      </PageHeading>

      <Title>Toucher la cible</Title>

      <Text>
        {`Avant de pouvoir blesser une cible, il faut d'abord la toucher. Pour ce faire, il faut lancer un dé sous la compétence de l'arme utilisée. Il est possible de viser une partie du corps en particulier, mais cela augmente la difficulté du jet.`}
      </Text>

      <Subtitle>Localisation</Subtitle>

      <Quote>
        {`Si une attaque n'est pas localisée, il faut inverser le dé des dizaines et celui des unités pour déterminer la localisation de l'attaque.`}
      </Quote>

      <Table data={localization}></Table>

      <Subtitle>La precision</Subtitle>

      <Text>
        {`Des modificateurs de précision existent selon la portée de l'arme mais également selon la situation :`}
      </Text>

      <Table data={precisionModifiers}></Table>

      <Title>Les blessures</Title>

      <Subtitle>Score de blessure</Subtitle>

      <Text>
        {`Afin de déterminer la blessure infligée, il faut regarder les détails de l'arme utilisée. Si le score de blessure d'une arme demande un jet de dé, celui-ci est indiqué dans la description de l'arme. De même s'il faut ajouter le degré de réussite naturel de l'attaque au score de blessures.`}
      </Text>

      <Text>
        {`De ce score de blessure, il faut déduire l'armure de la cible sur le membre touché.`}
      </Text>

      <Quote>
        {`Lors d'un succès critique sur le test de toucher, il faut ajouter le degré de réussite naturel au score de blessures.`}
      </Quote>

      <Subtitle>Blessure</Subtitle>

      <Text>
        {`Enfin, le tableau suivant permet de déterminer la blessure infligée selon le score de blessure. Une fois la blessure déterminée, il faut la noter sur la fiche de personnage.`}
      </Text>

      <Table data={wounds} sizes={[1, 4]}></Table>

      <Text>
        {`Lorsqu'un personnage reçoit une blessure, il perd immédiatement un point de mental. De plus, si le score de la blessure est supérieur ou égal à 15, le personnage gagne un point de psychose.`}
      </Text>

      <Subtitle>Conséquences</Subtitle>

      <Text>
        {`Les conséquences des blessures sont traitées au cas par cas: des jambes blessées affectent la mobilité, une hémorragie provoque des saignements constants qui peuvent être létaux, ...`}
      </Text>
    </>
  );
}
