
import { Table, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';
import bleedingData from 'data/rules/bleedingData';
import degreesData from 'data/rules/temperature';
import diseasesBeninData from 'data/rules/diseasesSoft';
import diseasesLethalData from 'data/rules/diseasesLethal';
import poisonData from 'data/rules/poisons';
import radiationsData from 'data/rules/radiations';
import radiationsMutationData from 'data/rules/radiationsMutations';

export function ManualEnvironmentPage() {
  return (
    <>
      <PageHeading>
        Environnement
      </PageHeading>

      <Title>{`Asphyxie, noyade et fumée`}</Title>

      <Text>
        {`Plusieurs cas de figure peuvent survenir où un personnage ne peut plus respirer : se faire étouffer, se noyer ou la fumée d'un incendie.`}
      </Text>

      <Text>
        {`Un personnage peut retenir son souffle autant de tours que son score en Endurance. Passé ce délai, il sera en manque d'oxygène.`}
      </Text>

      <Text>
        {`Pour chaque tour où un personnage manque d'oxygène, il perd un point de Moral et doit faire un test de Volonté. En cas d'échec, il gagne un point de Psychose, tombe inconscient et risque de décéder.`}
      </Text>

      <Title>{`Chute, chute d'objet`}</Title>

      <Text>
        {`Une chute peut arriver à n'importe qui ! Un personnage qui tombe et atterrit sur une surface solide va subir des blessures contondantes sur les jambes selon la hauteur : 1 par mètre au delà du 2ème.`}
      </Text>

      <Text>
        {`Si le personnage atterrit sur une surface qui amortit le choc, que ce soit un trampoline, une toile tendue ou bien de l'eau, le score de la blessure sera réduite de moitié.`}
      </Text>

      <Text>
        {`Dans l'autre cas de figure, si un objet tombe d'une certaine hauteur sur un personnage, il subira une blessure selon le poids de l'objet et la hauteur de chute.`}
      </Text>

      <Title>{`Chaleur et brûlure`}</Title>

      <Text>
        {`La température maximale qu'un personnage peut supporter est de 30° + son score en Endurance. Cette valeur peut varier selon l'équipement du personnage. Au-delà de cette température, le personnage doit effectuer un test de Volonté à chaque intervalle, et tombera inconscient en cas d'échec. Sa Psychose augmente de 1.`}
      </Text>

      <Table data={degreesData}></Table>

      <Title>Froid et gel</Title>

      <Text>
        {`Tout comme pour la chaleur, la température minimale qu'un personnage peut endurer est de 10° - son score en Endurance. De la même manière, cette valeur peut être modifiée avec l'équipement du personnage.`}
      </Text>

      <Title>Radiations</Title>

      <Text>
        {`Le taux de radiations peut augmenter de plusieurs façons, par l'ingestion de nourriture irradiée, par l'attaque d'un monstre irradié ou simplement en se baladant dans une zone irradiée.`}
      </Text>

      <Text>
        {`Le montant de radiations appliqué se réfère généralement à la table des intensités et il est directement ajouté au score de radiations du personnage, que ce soit ponctuel ou régulier.`}
      </Text>

      <Table data={radiationsData} sizes={[1, 3]}></Table>

      <Text>
        {`Si un personnage reste à un taux de radiations élevé pendant une période prolongée, et ce sans mourir, des effets supplémentaires peuvent survenir.`}
      </Text>

      <Table data={radiationsMutationData} sizes={[1, 3]}></Table>

      <Title>Poison</Title>

      <Text>
        {`Les effets du poison dépendent du type de poison qui circule dans les veines du personnage.`}
      </Text>

      <Table data={poisonData} sizes={[1, 2, 3]}></Table>

      <Title>Saignements</Title>

      <Text>
        {`Voici les différents degrés de saignements dont un personnage peut être atteint ainsi que le nombre de points de sang qui sont perdus en conséquence.`}
      </Text>

      <Table data={bleedingData}></Table>

      <Text>
        {`La durée des saignements est généralement indiquée avec l'effet qui l'a causé.`}
      </Text>

      <Title>Maladie</Title>

      <Text>
        {`Ce monde, bien que différent du nôtre, regorge de maladies que les personnages peuvent attraper. Différentes méthodes de transmission existent, que ce soit par le contact avec un agent infecté, par inhalation ou après avoir ingurgité des aliments avariés.`}
      </Text>

      <Text>
        {`Dès qu'un personnage entre en contact avec un agent pathogène d'une maladie, il doit faire un test de Vigueur. S'il échoue, il contracte la maladie juste après le temps d'incubation.`}
      </Text>

      <Text>
        {`Une fois malade, le personnage doit réitérer son test de Vigueur tous les jours. Son système immunitaire ne viendra à bout de la maladie qu'après avoir réussi deux de ces tests consécutivement.`}
      </Text>

      <Text>
        {`Il peut aussi bénéficier de médicaments afin de traiter la maladie, encore faut-il savoir lesquels prendre.`}
      </Text>

      <Table data={diseasesBeninData} sizes={[1, 1, 1, 3]}></Table>

      <Text>
        {`Attention toutefois, même les maladies les plus courantes, si elles persistent trop longtemps car le système ne parvient pas à les vaincre ou qu'elles ne sont pas soignées, peuvent être fatales pour les personnages. Il ne faut pas les négliger !`}
      </Text>

      <Text>
        {`Il existe également des maladies que le système immunitaire du personnage ne peut pas vaincre seul. Une fois ces maladies contractées, le personnage ne pourra être soigné qu'avec un traitement approprié.`}
      </Text>

      <Table data={diseasesLethalData} sizes={[1, 1, 1, 3]}></Table>
    </>
  );
}
