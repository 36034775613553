export class Race {

  #name;
  get name() { return this.#name; }

  #effects;
  get effects() { return this.#effects; }

  constructor(name, effects) {
    this.#name = name;
    this.#effects = effects;
  }

}

export const Races = {
  HUMAIN: new Race('Humain', []),
  SALBEK: new Race('Salbek', []),
  THOG: new Race('Thog', []),
  CYBORG: new Race('Cyborg', []),
  ELEMENTAIRE: new Race('Elementaire', []),
  ADRULEN: new Race('Adrulen', []),
}
