// import replaceAccentedCharacters from 'lib/normalizeString';
import { Table } from 'components/semantic';

export function CharacterMagicTable({ data }) {
  const magicData = data.reduce((output, m) => ([...output, [m.name, m.value]]), []);

  return (
    <Table data={magicData}></Table>
  )
}
