import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DataCard } from 'components/afterworld/data/card/DataCard';
import { CardPin } from 'components/afterworld/data/card/CardPin';
import { WeaponData } from 'components/afterworld/data/WeaponData';

export function Weapon(props) {
  const {
    name,
    value,
    weight,
    description = '',
  } = props;

  return (
    <DataCard
      content={<WeaponData {...props} />}
      description={description}
      title={name}
      bottom={[
        <CardPin key={0} tooltip={`Valeur`}>
          <FontAwesomeIcon icon={['fa', 'sack-dollar']} />
          {' '}
          {value}
        </CardPin>,
        <CardPin key={1} tooltip={`Poids`}>
          <FontAwesomeIcon icon={['fa', 'weight-hanging']} />
          {' '}
          {weight}
        </CardPin>
      ]}
    />
  );
}

Weapon.propTypes = {
  ammo: PropTypes.string,
  bpa: PropTypes.string,
  damage: PropTypes.string,
  description: PropTypes.string,
  effects: PropTypes.string,
  load: PropTypes.string,
  name: PropTypes.string.isRequired,
  range: PropTypes.string,
  value: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
  zone: PropTypes.string,
};
