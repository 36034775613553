import { PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';
import { AfterworldCard } from 'components/afterworld/cards/AfterworldCard';

import './DummyPage.scss';

const actions = [
  { t: 'Parler ou crier', a: 'Action pleine', c: (<>Parle ou crie jusqu'à un maximum 10 à 12 mots.<br /><br />Noter la ou les phrases énoncées sur une feuille.</>), i: 'images/cards/singing.webp' },
  { t: 'Se déplacer', a: 'Action pleine', c: (<>Se déplacer de 8 à 9 mètres.<br /><br />Noter la direction sur une feuille.</>), i: 'images/cards/running.webp' },
  {
    t: 'Attaquer', a: 'Action pleine', c: (<>Porte une attaque complète avec une arme en main.
      <br /><br />
      Noter les détails de l'attaque sur une feuille :
      <ul>
        <li>&middot; cible de l'attaque</li>
        <li>&middot; localisation</li>
        <li>...</li>
      </ul></>), i: 'images/cards/attack.webp'
  },
  { t: 'Incanter un sort', a: 'Action pleine', c: 'Incanter un sort.', i: 'images/cards/attack.webp' },
  { t: 'Fouiller un sac', a: 'Action pleine', c: 'Fouiller dans un sac afin de récupérer un objet.', i: 'images/cards/attack.webp' },
  { t: 'Se reposer', a: 'Action pleine', c: 'Se reposer pour récupérer 1 point de fatigue.', i: 'images/cards/attack.webp' },

  { t: 'Parler ou crier peu', a: 'Action rapide', c: 'Parle ou crie jusqu\'à un maximum 5 à 6 mots.', i: 'images/cards/yelling.webp' },
  { t: 'Se déplacer peu', a: 'Action rapide', c: 'Se déplacer de 4 à 5 mètres.', i: 'images/cards/attack.webp' },
  { t: 'Engainer', a: 'Action rapide', c: 'Engainer une arme.', i: 'images/cards/attack.webp' },
  { t: 'Dégainer', a: 'Action rapide', c: 'Dégainer une arme à portée de main.', i: 'images/cards/attack.webp' },
  { t: 'Recharger', a: 'Action rapide', c: 'Recharger une arme à feu.', i: 'images/cards/attack.webp' },
  { t: 'Viser', a: 'Action rapide', c: 'Se concentrer et viser avec une arme à feu.', i: 'images/cards/aiming.webp' },
  { t: 'Préparer un sort', a: 'Action rapide', c: 'Se concentrer pour se préparer à lancer un sort.', i: 'images/cards/attack.webp' },
  { t: 'Parer', a: 'Action rapide', c: 'Parer une attaque.', i: 'images/cards/attack.webp' },
  { t: 'Esquiver', a: 'Action rapide', c: 'Esquiver une attaque.', i: 'images/cards/attack.webp' },
  { t: 'Prendre un objet', a: 'Action rapide', c: 'Saisir un objet à portée de main.', i: 'images/cards/attack.webp' },
  { t: 'Utiliser un objet', a: 'Action rapide', c: 'Utiliser un objet déjà en main.', i: 'images/cards/attack.webp' },

  { t: 'Parler ou crier très peu', a: 'Action gratuite', c: 'Parle ou crie jusqu\'à un maximum 1 à 2 mots.', i: 'images/cards/attack.webp' },
  { t: 'Se déplacer très peu', a: 'Action gratuite', c: 'Se déplacer de ~1 mètre.', i: 'images/cards/attack.webp' },
  { t: 'Analyser une situation', a: 'Action gratuite', c: 'Analyser une situation.', i: 'images/cards/attack.webp' },
  { t: 'Maintenir un sort', a: 'Action gratuite', c: 'Maintenir un sort.', i: 'images/cards/attack.webp' },
];
export function DummyPage() {
  return (
    <PageRootContainer target="/bestiary">
      <PageHeading>
        {`Dummy page`}
      </PageHeading>

      {actions.map((a) => (
        <AfterworldCard imageUrl={a.i} textMiddleBarLeft={a.a} title={a.t}>
          {a.c}
        </AfterworldCard>
      ))}

    </PageRootContainer >
  );
}
