import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import gifts from 'data/gifts';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Gift } from 'components/afterworld/data/Gift';
import { PageHeading } from 'components/elements';
import { useStore } from 'store';

const giftsLevels = [...new Set(gifts.map((gift) => (gift.level)))];

export function DataGiftPage() {
  const { level, search, setFilter } = useStore(useShallow((state) => ({
    level: state.filter_gift_level,
    search: state.filter_gift_search,
    setFilter: state.setFilter,
  })));

  const filteredData = gifts
    .filter((datum) =>
      (!level || datum.level === level)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Aptitudes
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-gifts__list-filter__label">
          Niveau
        </InputLabel>

        <Select
          labelId="afterworld-gifts__list-filter__label"
          id="afterworld-gifts__list-filter"
          value={level}
          label="Niveau"
          onChange={(e) => { setFilter('gift', 'level', e.target.value); }}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {giftsLevels.map((filterItem) => (
            <MenuItem key={filterItem} value={filterItem}>{filterItem}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-gifts__list-search"
          label="Rechercher l'aptitude"
          onChange={(e) => { setFilter('gift', 'search', e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        density="comfortable"
        mobileComponent={Gift}
      />
    </>
  );
}
