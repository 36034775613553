import styled from "@emotion/styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Switch } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';
import { useStore } from 'store';

const ToggleContainer = styled.div`
  position: absolute;
  right: 0;
  text-align: right;
`;

export function CharacterDetailsPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const { character, editMode, toggleEditMode } = useStore(useShallow((state) => ({
    character: state.characters[characterId],
    editMode: state.editMode,
    toggleEditMode: state.toggleEditMode,
  })));

  const rightContent = (<ToggleContainer>
    <Switch onClick={toggleEditMode} inputProps={{ 'aria-label': 'Switch demo' }} value={editMode}></Switch>
  </ToggleContainer>);

  return (
    <PageRootContainer target="/">
      <PageHeading
        backButton={(<Link to="/characters"><FontAwesomeIcon icon={['fa', 'arrow-left']} /></Link>)}
        rightContent={rightContent}
      >
        {character.name}
      </PageHeading>
      <Outlet />
    </PageRootContainer>
  );
}
