import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseLocationEuropePage() {
  return (
    <>
      <PageHeading>
        {`L'Europe`}
      </PageHeading>

      <Title>{`Paysage`}</Title>

      <Text>
        {`L’Europe, autrefois le cœur battant de l’histoire et de la culture, porte aujourd’hui les stigmates de la grande guerre et des crises qui ont précédé l’effondrement. Ses principales grandes villes, jadis des joyaux de pierre et de verre, ont été ravagées par des frappes stratégiques, mais ce ne sont pas les bombes qui les ont d'abord plongées dans la ruine. Les bouleversements climatiques, les crises sociales et les guerres intestines avaient déjà précipité leur déclin, et certaines métropoles étaient devenues des ruines bien avant que le monde ne bascule définitivement. Ces villes, délaissées par l’espoir d’une civilisation déclinante, ont été pillées, vidées de leurs trésors et de leur humanité, et leurs carcasses éventrées demeurent comme des fantômes muets du passé. Quant aux campagnes, elles oscillent entre des étendues florissantes et des terres asséchées, épuisées par des décennies d’exploitation et empoisonnées par des produits chimiques dont les effets perdurent. Le climat capricieux et impitoyable sculpte désormais cette terre abandonnée.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/locations/europe-paris.webp" />
        <Image size="large" src="/images/universe/locations/europe-london.webp" />
        <Image size="large" src="/images/universe/locations/europe-rome.webp" />
      </Gallery>

      <Title>{`Présence humaine`}</Title>

      <Text>
        {`La vie humaine s’accroche ici, telle une mauvaise herbe poussant à travers les fissures d’un pavé ancien. Sur ces terres déjà marquées avant la guerre, les survivants se sont regroupés, formant de grandes villes où règne un semblant d’ordre, ou peut-être seulement une version déformée de la civilisation passée. Ce rassemblement offre un fragile espoir, mais il révèle aussi les pires travers de l’humanité. La méfiance, la rivalité et la lutte pour le pouvoir sont omniprésentes, tandis que les plus forts ou les plus rusés façonnent leur propre autorité. Chaque ville est un microcosme de rêves brisés, de trahisons et de petites dictatures, où le moindre vestige d’ancien savoir devient une monnaie d’échange, et la survie dépend souvent de la force ou de la ruse.`}
      </Text>

      <Title>{`Présences non humaines`}</Title>

      <Text>
        {`En raison de la densité humaine, les créatures mutantes et les animaux étranges sont devenus rares, repoussés ou chassés jusqu’aux confins les plus désolés. Les quelques mutants et êtres monstrueux qui subsistent rôdent dans les ombres, discrets et vigilants, car ils savent bien que les humains voient en eux des proies à éliminer. Mais, en vérité, le plus grand danger en Europe ne vient pas des créatures sauvages ou des anomalies de la nature. Ce sont les hommes eux-mêmes qui représentent la menace la plus insidieuse. Dans ces territoires marqués par la brutalité et l’oppression, la violence, la trahison et l'ambition dévorante sont les véritables prédateurs, qui rôdent dans chaque recoin, dissimulés derrière des sourires ou des serments d’allégeance.`}
      </Text>
    </>
  );
}
