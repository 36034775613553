import { Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function UniverseLocationPlansPage() {
  return (
    <>
      <PageHeading>
        {`Les plans`}
      </PageHeading>

      <Title>{`La cosmologie des plans`}</Title>

      <Text>
        {`Tout comme le temps représente une quatrième dimension difficilement appréhendable, les plans d'existence peuvent être perçus comme une dimension supplémentaire, une toile cachée entrelacée avec notre réalité. Ces plans, parfois proches, parfois lointains et incompréhensibles, constituent un multivers dans lequel chaque monde possède ses propres lois et sa propre essence.`}
      </Text>

      <Subtitle>{`L'entropie`}</Subtitle>

      <Text>
        {`L'ensemble des plans baignent dans une soupe cosmologique nommée l'Entropie. Aucune loi d'existence n’y prend forme ; c'est un non-lieu, un espace de chaos absolu, où l'énergie tourbillonne sans règle et sans fin. Dans cet espace chaotique, la vie n'est possible sous aucune forme, et tout ce qui s'aventure dans l'Entropie est voué à se disloquer dans le néant. Elle est le tissu brut de l'univers, un rappel constant de l'instabilité fondamentale de toute existence.`}
      </Text>

      <Text>
        {`Certains plans sont matériels, où la vie se manifeste sous des formes tangibles, soumises aux lois physiques. D'autres sont immatériels, des royaumes d'énergie pure ou de pensée, où les lois qui les régissent permettent des existences plus éthérées et des formes de vie sans manifestation physique.`}
      </Text>

      <Subtitle>{`Le plan des vivants`}</Subtitle>

      <Text>
        {`Le plan dans lequel les gens vivent et où la majeure partie des histoires se déroulent est le plan des vivants. Ce plan est régi par des lois physiques telles que la gravité, le temps et la matière. C'est ici que résident la Terre et toutes les planètes de la galaxie, un vaste territoire où les êtres vivants expérimentent le monde à travers une matérialité physique qui limite et définit leur expérience.`}
      </Text>

      <Text>
        {`Le plan des vivants est bien connu, mais il n'est qu'un maillon parmi les multiples plans d'existence. Les individus qui tentent d'en franchir les frontières s'exposent à des risques extrêmes, car les lois de chaque plan influencent les intrus de façons imprévisibles et parfois destructrices.`}
      </Text>

      <Subtitle>{`Changer de plan`}</Subtitle>

      <Text>
        {`Voyager entre les plans est une entreprise périlleuse, réservée aux rares individus dotés de compétences en transplanage. Un personnage sans ces compétences et qui séjourne trop longtemps hors de son plan d'origine en subit les conséquences : dans un plan matériel, il accumule des points de Psychose, sa perception du monde devenant de plus en plus fragmentée et instable. Dans un plan immatériel, il commence à perdre des compétences et des capacités, comme si son essence même était dissoute par ce nouvel environnement.`}
      </Text>

      <Title>{`Les plans proches`}</Title>

      <Subtitle>{`Plan des morts`}</Subtitle>

      <Text>
        {`Directement lié au plan des vivants, ce plan est un lieu de transition où se rendent les âmes des défunts. Il est matériel, mais ses lois diffèrent subtilement de celles du plan des vivants. Ici, le temps et la matière semblent flous et altérés, et le passage entre les deux plans est souvent marqué par des apparitions ou des visions pour les vivants sensibles.`}
      </Text>

      <Subtitle>{`Plan élémentaire`}</Subtitle>

      <Text>
        {`Un plan d'existence matériel où chaque élément de la magie - le feu, l'eau, la terre, et l'air - existe sous sa forme la plus pure et sauvage. Les créatures qui y vivent incarnent l'essence de leur élément, et leur comportement et apparence sont façonnés par celui-ci. Ce plan est en perpétuelle transformation, les forces élémentaires s'affrontant et se mêlant dans une danse éternelle.`}
      </Text>

      <Subtitle>{`Plan de l'ombre`}</Subtitle>

      <Text>
        {`Ce plan est immatériel et baigné dans une obscurité perpétuelle, où la lumière est déformée et l'énergie semble figée. Les êtres qui y résident sont des entités spectrales, des reflets déformés des vivants, souvent créés par des émotions ou des événements tragiques. Traverser ce plan est risqué, car il est facile d'y perdre tout sens de la réalité et de devenir soi-même une ombre.`}
      </Text>

      <Title>{`Les plans éloignés`}</Title>

      <Subtitle>{`Plan astral`}</Subtitle>

      <Text>
        {`Immatériel et infiniment vaste, le plan astral est un domaine de pensée et de potentiel pur. Il s'agit d'un espace où les esprits et les idées prennent forme, un royaume d'intuition et de connexion spirituelle. Ceux qui s'y aventurent peuvent voir leurs pensées devenir réalité, mais risquent également d'être submergés par le flot des consciences environnantes.`}
      </Text>

      <Subtitle>{`Plan des dieux`}</Subtitle>

      <Text>
        {`Ce plan apparaît comme une étendue infinie de nuages solides aux teintes changeantes, du jaune doux au rose flamboyant, recouvrant ciel et sol. Les individus qui séjournent ici voient leur essence fusionner peu à peu avec le plan, acquérant une divinité naissante. Dans ce royaume, la puissance d'un dieu dépend de l'ampleur de son culte ; sans adorateurs, il s'efface. Les règles y sont strictes : aucun dieu ne peut avoir des fidèles d'un autre plan, et le panthéon exige un remplacement immédiat lorsqu'un dieu disparaît.`}
      </Text>

      <Subtitle>{`Plan des esprits`}</Subtitle>

      <Text>
        {`Un plan immatériel, flottant entre le monde des vivants et celui des morts. Il est le domaine des esprits ancestraux, des souvenirs vivants et des gardiens immatériels. La frontière entre ce plan et celui des vivants est ténue, et certaines âmes, incapables de se détacher des émotions fortes ou des devoirs inachevés, y errent éternellement.`}
      </Text>

      <Title>{`Les plans inconnus`}</Title>

      <Text>
        {`Au-delà des plans répertoriés ici, il en existe d’autres, bien plus éloignés, enveloppés de mystères insondables. Aucun être connu n’a jamais réussi à atteindre ces contrées ni à en revenir, mais les légendes murmurent qu'ils abriteraient des forces, des créatures, et des savoirs aussi dangereux que fascinants. Ces plans inconnus représentent le défi ultime, l'appel irrésistible pour les aventuriers les plus intrépides, prêts à risquer leur vie pour explorer les confins du multivers.`}
      </Text>
    </>
  );
}
