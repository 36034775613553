export default Object.freeze([
  [`Score de faim`, `Effet`],
  [`19`, `Irritabilité, inconfort`],
  [`18`, `Irritabilité accrue, Fatigue légère`],
  [`17`, `Faiblesse musculaire, concentration difficile`],
  [`16`, `Irritabilité intense, étourdissements, maux de tête`],
  [`15 - 10`, `Faiblesse extrême, vertiges, confusion mentale, perte de masse musculaire`],
  [`10 - 5`, `Hallucinations, perte de conscience, incapacité à se mouvoir, vision floue`],
  [`5 - 0`, `Coma, défaillance des organes vitaux`],
]);
