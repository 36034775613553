export class Rollable {

  #spent = 0;
  get spent() { return this.#spent; }
  set spent(v) { this.#spent = parseInt(v, 10); }

  #experience = 0;
  get experience() { return this.#experience; }
  set experience(v) { this.#experience = parseInt(v * 2, 10) / 2; }

  #specialized = false;
  get specialized() { return this.#specialized; }
  set specialized(v) { this.#specialized = !!v; }

}
