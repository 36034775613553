import { useParams } from 'react-router-dom';

import { useStore } from 'store';

export function CharacterGiftsPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const character = useStore((state) => state.characters[characterId]);

  return (
    <p>
      Items: {character.name}
    </p>
  );
}
