const aptitudes = [
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.EMPATHY} +10`],
    name: `Altruiste`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING} +10`],
    name: `Analytique`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.FOCUS} +10`],
    name: `Attentif`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.INTIMIDATION} +10`],
    name: `Autoritaire`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.INTELLECT} +10`],
    name: `Brillant`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.TALKING} +10`],
    name: `Charismatique`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY} +10`],
    name: `Compassionnel`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.NEGOTIATE} +10`],
    name: `Conflictuel`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.CULTURE} +10`],
    name: `Curieux`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +10`],
    name: `Impassible`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL} +10`],
    name: `Méthodique`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.ACUITY} +10`],
    name: `Observateur`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.VIGOR} +10`],
    name: `Résilient`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.WILL} +10`],
    name: `Résolu`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.STRENGTH} +10`],
    name: `Robuste`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.AGILITY} +10`],
    name: `Souple`,
  },
];

export default aptitudes.map((a) => ({ ...a, level: 1, usable: 2 }));
