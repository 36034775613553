import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export function GeneratorsMenu() {
  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'person']} />}
        to="/generators/character"
      >
        Personnage
      </Button>

      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'store']} />}
        to="/generators/shop"
      >
        Boutique
      </Button>
    </>
  );
}
