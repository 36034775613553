import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { Table, Title } from 'components/semantic';
import { useStore } from 'store';

export function CharacterGeneralPage() {
  const params = useParams();
  const characterId = params['*'].split('/').pop();
  const { character, editMode } = useStore(useShallow((state) => ({
    character: state.characters[characterId],
    editMode: state.editMode,
  })));

  let isLegal = 'Oui';
  try { character.isLegal(); }
  catch (error) { isLegal = error; }

  const characterData = [
    [`Nom`, character.name],
    [`Niveau`, character.level],
    [`Race`, character.race.name],
    [`Légal`, isLegal],
    [`Sommeil`, character.sleep],
    [`Main d'usage`, character.mainHand],
  ];

  const characterRolePlayData = [
    [`Description physique`, character.descriptionPhysical],
    [`Description psychologique`, character.descriptionPsychological],
    [`Objectif court terme`, character.objectiveShortTerm],
    [`Objectif long terme`, character.objectiveLongTerm],
    [`Folies`, character.madnesses],
  ];

  const characterPointsData = [
    [`Points de chance`, character.pointsLuck],
    [`Points divins`, character.pointsDivine],
    [`Points de psychose`, character.pointsPsychose],
    [`Points de fatigue`, character.pointsFatigue],
  ];

  return (
    <>
      {editMode && <p>Mode édition</p>}

      <Title>{`Personnage`}</Title>
      <Table data={characterData}></Table>

      <Title>{`Caractère`}</Title>
      <Table data={characterRolePlayData}></Table>

      <Title>{`Points`}</Title>
      <Table data={characterPointsData}></Table>
    </>
  );
}
