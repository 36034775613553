// function removeAccents(str) {
//   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
// }

export default function replaceAccentedCharacters(inputString) {
  const accentsMap = {
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
    'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
    'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
    'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
    'ã': 'a', 'õ': 'o',
    'ñ': 'n',
    'ç': 'c',
    'ý': 'y', 'ÿ': 'y',
    'æ': 'ae', 'œ': 'oe'
    // add more if needed
  };

  return inputString.replace(/[^\x00-\x7F]/g, function (character) {
    return accentsMap[character] || character;
  });
}
