import { EffectOperation } from './effects';
import { Rollable } from './internal/Rollable';

export const SkillCategory = Object.freeze({
  Fight: `Combat`,
  Knowledge: `Savoir`,
  Social: `Social`,
  Resistance: `Résistance`,
  Mastery: `Maîtrise`,
});

export class Skill extends Rollable {

  #character;

  #category;
  get category() { return this.#category; }

  #name;
  get name() { return this.#name; }

  constructor(character, category, name) {
    super();
    this.#character = character;
    this.#category = category;
    this.#name = name;
  }

  get value() {
    return 20 + this.spent + this.experience + (this.specialized ? 30 : 0) + this.modifiers;
  }

  get modifiers() {
    const relatedEffects = this.#character.effects.filter((e) => (
      (e.type === Skill && e.target === this.#name)
      || (e.type === SkillCategory && e.target === this.#category))
    );
    let mod = relatedEffects.filter((e) => (e.operation === EffectOperation.Additive)).reduce((total, v) => (total + v.value), 0);
    relatedEffects.filter((e) => (e.operation === EffectOperation.Multiplicative)).forEach((effect) => {
      mod *= effect.value;
    });
    return mod;
  }

  static createFullSet(character) {
    return [
      new Skill(character, SkillCategory.Fight, `Armes légères`),
      new Skill(character, SkillCategory.Fight, `Armes lourdes`),
      new Skill(character, SkillCategory.Fight, `Corps-à-corps`),
      new Skill(character, SkillCategory.Fight, `Explosifs, Lancer`),
      new Skill(character, SkillCategory.Knowledge, `Biologie`),
      new Skill(character, SkillCategory.Knowledge, `Culture`),
      new Skill(character, SkillCategory.Knowledge, `Ingénierie`),
      new Skill(character, SkillCategory.Knowledge, `Survie`),
      new Skill(character, SkillCategory.Social, `Empathie`),
      new Skill(character, SkillCategory.Social, `Intimidation`),
      new Skill(character, SkillCategory.Social, `Négociation`),
      new Skill(character, SkillCategory.Social, `Rhétorique`),
      new Skill(character, SkillCategory.Resistance, `Concentration`),
      new Skill(character, SkillCategory.Resistance, `Sang froid`),
      new Skill(character, SkillCategory.Resistance, `Vigueur`),
      new Skill(character, SkillCategory.Resistance, `Volonté`),
      new Skill(character, SkillCategory.Mastery, `Acuité`),
      new Skill(character, SkillCategory.Mastery, `Agilité`),
      new Skill(character, SkillCategory.Mastery, `Force`),
      new Skill(character, SkillCategory.Mastery, `Intelligence`),
    ];
  }

  serialize() {
    return {
      category: this.category,
      experience: this.experience,
      name: this.name,
      specialized: this.specialized,
      spent: this.spent,
    };
  }

  static deserialize(character, savedData) {
    const skill = new Skill(character, savedData.category, savedData.name);
    skill.experience = savedData.experience;
    skill.specialized = savedData.specialized;
    skill.spent = savedData.spent;
    return skill;
  }

}
