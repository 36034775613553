
import { Image, Table } from 'components/semantic';
import { PageHeading } from 'components/elements';
import statesData from 'data/rules/states';

export function ManualStatesPage() {
  return (
    <>
      <PageHeading>
        Etat de sante
      </PageHeading>

      <Table data={statesData} headers={[`Type`, `Effet`, `Contre`]}></Table>

      <Image size="large" src="/images/manual/other/states.webp" />
    </>
  );
}
