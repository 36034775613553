import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import ServiceWorkerContext from 'layout/service-worker/ServiceWorkerContext';

export function ServiceWorkerRegistration({ children }) {
  const [serviceWorker, setServiceWorker] = useState({ serviceWorker: null });

  useEffect(() => {
    const onLoadCallback = () => {
      navigator.serviceWorker.register('/service-worker.js', { scope: '/' }).then((registration) => {
        setServiceWorker({ serviceWorker: registration });
      })
    };

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', onLoadCallback);
    }

    return () => {
      window.removeEventListener('load', onLoadCallback);
    };
  }, [setServiceWorker]);


  return (
    <ServiceWorkerContext.Provider value={serviceWorker}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}

ServiceWorkerRegistration.propTypes = {
  children: PropTypes.node.isRequired,
};
