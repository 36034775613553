import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { AdaptativeText } from 'components/afterworld/cards/AdaptativeText';
import replaceAccentedCharacters from 'lib/normalizeString';

const CardBackground = styled.div`
  background: black;
  border-radius: 4px;
  box-shadow: 0px 0px 2px black;
  display: inline-block;
  font-family: Luciole;
  font-size: 10px;
  height: calc(3.5in - 12px);
  padding: 6px;
  position: relative;
  width: calc(2.5in - 12px);
`;

const CardFrame = styled.div`
  background: DarkSlateGray;
  border-radius: 6px;
  box-shadow: 0px 0px 4px DarkSlateGray;
  height: calc(100% - 12px);
  padding: 6px;
`;

const CardContent = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  justify-content: space-between;
`;

const CardHeader = styled.div`
  background: linear-gradient(
    45deg,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 80%,
    #999 95%
  );
  border-radius: 2px;
  box-shadow: inset 0px 0px 4px black;
  font-family: Scorchedearth;
  font-size: 16px;
  letter-spacing: 2px;
  min-height: 10%;
  line-height: 32px;
  padding: 0 8px;
  text-align: center;
  text-shadow: 0px 0px 2px silver;
  white-space: nowrap;
`;

const CardImageArea = styled.div`
  background: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  max-height: 30%;
  min-height: 30%;
  overflow: hidden;
  position: relative;

  &>img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &>img::after {
    box-shadow: inset 0px 0px 5px black;
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const CardImageAreaShadow = styled.div`
  box-shadow: inset 0px 0px 15px DarkSlateGray;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const CardMiddleBar = styled.div`
  background: Gainsboro;
  box-shadow: inset 0px 0px 4px DarkSlateGray;
  display: flex;
  min-height: 5%;
  justify-content: space-between;
  line-height: 16px;
  padding: 0px 5px;
  position: relative;
`;

const CardContentArea = styled.div`
  background: Gainsboro;
  box-shadow: inset 0px 0px 4px DarkSlateGray;
  flex: 1;
  font-size: 12px;
  padding: 8px;
  text-align: justify;
`;

const CardFooter = styled.div`
  background: Gainsboro;
  box-shadow: inset 0px 0px 4px DarkSlateGray;
  display: flex;
  font-family: Scorchedearth;
  min-height: 5%;
  justify-content: space-between;
  line-height: 16px;
  padding: 0 8px;
  position: relative;
  text-align: center;
  white-space: nowrap;
`;

const CardFooterCorner = styled.div`
  overflow: hidden;
  width: 15%;
`;

export function AfterworldCard({
  children,
  imageUrl,
  textBottomBarCenter,
  textBottomBarLeft,
  textBottomBarRight,
  textMiddleBarLeft,
  textMiddleBarRight,
  title,
}) {
  const displayMiddleBar = textMiddleBarLeft || textMiddleBarRight;
  const displayBottomBar = textBottomBarCenter || textBottomBarLeft || textBottomBarRight;
  const imageSrc = imageUrl || 'images/cards/placeholder.webp';

  return (
    <CardBackground>
      <CardFrame>
        <CardContent>
          <CardHeader>
            <AdaptativeText>
              {replaceAccentedCharacters(title)}
            </AdaptativeText>
          </CardHeader>

          <CardImageArea>
            <img src={imageSrc} alt="placeholder" />
            <CardImageAreaShadow></CardImageAreaShadow>
          </CardImageArea>

          {displayMiddleBar && (
            <CardMiddleBar>
              <div>
                {textMiddleBarLeft}
              </div>
              <div>
                {textMiddleBarRight}
              </div>
            </CardMiddleBar>
          )}

          <CardContentArea>
            {children}
          </CardContentArea>

          {displayBottomBar && (
            <CardFooter>
              <CardFooterCorner>
                {textBottomBarLeft}
              </CardFooterCorner>
              <div>
                {textBottomBarCenter}
              </div>
              <CardFooterCorner>
                {textBottomBarRight}
              </CardFooterCorner>
            </CardFooter>
          )}
        </CardContent>
      </CardFrame>
    </CardBackground>
  );
}

AfterworldCard.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
