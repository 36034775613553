import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 2,
    description: `Force un élémentaire à venir depuis le plan élémentaire. Il sera du même élément que la relique utilisée.`,
    name: `Appel d'élémentaire pur`,
    requirements: [
      `relique (Bouteille pleine de l'élément concerné)`,
      `composant (Glande de Mut-moustique)`,
      `composant (Patte de Mut-gecko)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 2,
    description: `Force une ombre depuis le plan des ombres.`,
    name: `Appel d'ombre`,
    requirements: [
      `relique (Ombre en bouteille)`,
      `composant (Pétale de Spéléla)`,
      `composant (Pince de Mut-crabe)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 2,
    description: `Force un mort à venir depuis le plan des morts.`,
    name: `Appel d'un mort`,
    requirements: [
      `relique (Organe pourri conservé)`,
      `composant (Dent de chien)`,
      `composant (Mandibule de Mut-fourmi)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Agilité +50 définitivement`,
      `Effet : blessures subies +5 sur les bras et les jambes`,
      `Effet : Social -10`,
    ],
    cost: 2,
    description: `Toutes les articulations ne font plus que le tiers de leur taille, comme s'il n'y avait aucun os à l'intérieur.`,
    name: `Mutation: articulations démoniaques`,
    requirements: [
      `contact`,
      `composant (Fiole de vase)`,
      `composant (Sang de Mut-vache)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Intelligence +50 définitivement`,
      `Effet : les dégâts dans le cerveau sont létaux`,
      `Effet : Social -10`,
    ],
    cost: 2,
    description: `Le cerveau triple de volume ce qui brise la boîte crânienne et le fait dépasser au sommet de la tête.`,
    name: `Mutation: cerveau démoniaque`,
    requirements: [
      `contact`,
      `composant (Rein de Mut-cochon)`,
      `composant (Cerveau de Mut-mouche)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Force +50 définitivement`,
      `Effet : les saignements subit sont augmentés de 1 grade`,
      `Effet : Social -10`,
    ],
    cost: 2,
    description: `Les muscles doublent de volume et les veines deviennent saillantes.`,
    name: `Mutation: muscles démoniaques`,
    requirements: [
      `contact`,
      `composant (Corne de Mut-bélier)`,
      `composant (Carapace de Mut-tortue)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Acuité +50 définitivement`,
      `Effet : les lumières fortes ou les bruits forts sont davantage impactants`,
      `Effet : Social -10`,
    ],
    cost: 2,
    description: `Les yeux deviennent noirs et rouges, les oreilles doublent de taille et deviennent rougeâtres.`,
    name: `Mutation: yeux et oreilles démoniaques`,
    requirements: [
      `contact`,
      `composant (Oeil de Mut-taupe)`,
      `composant (Antenne de Mut-cafard)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : canaliser un sort ne permet pas de se déplacer naturellement`],
    cost: 2,
    description: `Permet à une personne de conserver son intégrité lorsqu'elle visite un autre plan, et donc de ne pas souffrir des transformations du séjour planaire.`,
    name: `Résilience planaire`,
    requirements: [`contact`],
    types: [Spells.Types.CHANNEL],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 4,
    description: `Force un esprit à venir depuis le plan des esprits.`,
    name: `Appel d'esprit`,
    requirements: [
      `relique (Rêve en bouteille)`,
      `composant (Extrait de Syla)`,
      `composant (Racine de tréant)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 4,
    description: `Force un dieu à venir depuis le plan divin.`,
    name: `Appel de dieu`,
    requirements: [
      `relique (Statue héroïque)`,
      `composant (Racine de moréant)`,
      `composant (Patte de Bergaul)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 4,
    description: `Force une fée à venir depuis le plan astral.`,
    name: `Appel de fée`,
    requirements: [
      `relique (Poussière de lune)`,
      `composant (Queue de Mut-scorpion)`,
      `composant (Extrait d'Amyrexia)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Magie +5 définitivement`,
      `Effet : Social -20`,
    ],
    cost: 4,
    description: `Une connexion permanente avec le chaos est établie, des gerbes d'étincelles multi-colores émanent de zone ombragée temporaires qui apparaissent sur la personne.`,
    name: `Mutation: aura démoniaque`,
    requirements: [
      `contact`,
      `composant (Main de Clampignon)`,
      `composant (Patte de Mycognon)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Mental +5 définitivement`,
      `Effet : Endurance +5 définitivement`,
      `Effet : Social -20`,
    ],
    cost: 4,
    description: `Les nerfs deviennent visibles et explosent par endroit, ce qui coupe certaines terminaisons et réduit la sensibilité à la douleur.`,
    name: `Mutation: nerfs démoniaques`,
    requirements: [
      `contact`,
      `composant (Gelée de ver de vase)`,
      `composant (Foie de Goun tao)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Initiative +5 définitivement`,
      `Effet : Social -20`,
    ],
    cost: 4,
    description: `Les os réduisent de taille et de poids de moitié ce qui donne une silhouette filiforme.`,
    name: `Mutation: ossature démoniaque`,
    requirements: [
      `contact`,
      `composant (Corne de Mut-cerf)`,
      `composant (Soie de Mut-araignée)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Armure +5 définitivement`,
      `Effet : Social -20`,
    ],
    cost: 4,
    description: `La peau devient pourpre et la texte se rapproche davantage du cuir tanné.`,
    name: `Mutation: peau démoniaque`,
    requirements: [
      `contact`,
      `composant (Pétale de Mut-lotus)`,
      `composant (Extrait de Bulbododo)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    cost: 4,
    description: `Voyage vers un autre plan adjacent.`,
    name: `Transplanage`,
    requirements: [

      `composant (Pince de Pestenfer)`,
      `composant (Griffe d'épouvantail de l'ombre)`,
      `composant (Croc de Basilisk)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : les entités venus d'un autre plan ne sont pas nécessairement obéissantes.`],
    cost: 6,
    description: `Force un démon à venir depuis le chaos magique qui règne entre les plans.`,
    name: `Démon`,
    requirements: [
      `relique (Figurine démoniaque)`,
      `composant (Feuille de Neothelid)`,
      `composant (Queue de Mut-garou)`,
      `composant (Cerveau d'écorcheur)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    cost: 6,
    description: `Crée un portail vers un autre plan adjacent.`,
    name: `Portail planaire`,
    requirements: [
      `composant (Épine de Cactopus)`,
      `composant (Croc de Mut-serpent)`,
      `composant (Oeil de Mut-requin)`,
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : Compétences +50 définitivement`,
      `Effet : Magies +30 définitivement`,
      `Effet : Passifs +5 définitivement`,
      `Effet : gagne 5 folies au hasard`,
    ],
    cost: 6,
    description: `Transforme définitivement en démon, faisant disparaître toute humanité restante. Le personnage devient injouable.`,
    name: `Mutation: Démon`,
    requirements: [
      `toutes les autres mutations`,
      `composant (Museau de Mut-ours)`,
      `composant (Estomac de Mut-crocodile)`,
    ],
    types: [Spells.Types.CAST],
  },
]);

export default data.map((i) => ({
  ...i,
  schools: [{ name: Spells.School.PLANS }],
}));
