import PropTypes from 'prop-types';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import styled from "@emotion/styled";

const MUIListStyled = styled(MUIList)(() => ({
  border: 'dotted 1px DarkSlateGray',
  borderRadius: 6,
  marginBottom: 16,
  overflow: 'hidden',
  padding: 0,
}));

const MUIListItemStyled = styled(MUIListItem)(() => ({
  borderBottom: 'dotted 1px DarkSlateGray',
  fontFamily: 'Luciole',
  fontSize: 14,

  '&:last-child': {
    borderBottom: 0,
  },
  '&:nth-of-type(even)': {
    background: '#F0F0F0',
  },
  '&:nth-of-type(odd)': {
    background: '#FAFAFA',
  },
}));

export function List({
  displayKey = '',
  onClick = () => { },
  data,
  ...restProps
}) {
  return (
    <MUIListStyled>
      {!!data.length && data.map((element) => {
        const displayedContent = displayKey ? element[displayKey] : element;
        return (
          <MUIListItemStyled key={displayedContent} onClick={() => onClick(element)} {...restProps}>
            {displayedContent}
          </MUIListItemStyled>
        );
      })}
      {!data.length && (
        <MUIListItemStyled>Aucun élément dans la liste.</MUIListItemStyled>
      )}
    </MUIListStyled>
  );
}

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
};
