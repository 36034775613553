
import { AppRouter } from 'layout/routing/AppRouter';
import { ServiceWorkerRegistration } from 'layout/service-worker/ServiceWorkerRegistration';
import { ServiceWorkerUpdateGuard } from 'layout/service-worker/ServiceWorkerUpdateGuard';

function App() {
  return (
    <div className="App">
      <ServiceWorkerRegistration>
        <ServiceWorkerUpdateGuard>
          <AppRouter />
        </ServiceWorkerUpdateGuard>
      </ServiceWorkerRegistration>
    </div>
  );
}

export default App;
