import { List, Quote, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function WarningPage() {
  return (
    <>
      <PageHeading>
        {`L'univers`}
      </PageHeading>

      <Title>Avertissement</Title>

      <Text>
        {`Cette section présente l'univers du jeu. Avant de poursuivre, il est important de considérer les mises en garde suivantes.`}
      </Text>

      <List data={[
        'Lire ces pages peut vous révéler des informations réservées aux maîtres de jeu, non destinées aux joueurs.',
        'Les connaissances acquises ici ne sont pas accessibles à votre personnage en jeu.',
        `Ne basez pas vos attentes en jeu sur les informations contenues dans cette section.`
      ]}></List>

      <Text>
        {`Gardez toujours à l'esprit la distinction entre vos connaissances de joueur et celles de votre personnage.`}
      </Text>

      <Text>
        {`Si vous pensez avoir du mal à faire cette distinction en cours de jeu, il est recommandé de ne pas poursuivre la lecture de cette section.`}
      </Text>

      <Quote>
        {`Un personnage qui ignore ces informations ne pourra pas les utiliser pour orienter ses choix ou ses objectifs.`}
      </Quote>
    </>
  );
}
