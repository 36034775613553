import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseLocationAmericasPage() {
  return (
    <>
      <PageHeading>
        {`Les Amériques`}
      </PageHeading>

      <Title>{`Paysage`}</Title>

      <Text>
        {`Les vastes étendues des Amériques ne sont plus que l'ombre de ce qu'elles étaient autrefois. Les plus grandes villes, jadis des symboles de prospérité et d'ingéniosité humaine, gisent en ruines, détruites par les frappes nucléaires d’un passé lointain. Ces cités éventrées, silencieuses et hantées par les ombres de leur grandeur passée, demeurent des zones dangereusement irradiées, où la radioactivité ronge tout sur son passage. Ici, la nature n’a pas vraiment réussi à reprendre ses droits : les sols sur-exploités, stérilisés par des décennies de pollution et de négligence, ne supportent plus que des étendues de terre craquelée, des marais de boue toxique et des reliques de bitume fendillé. Ce paysage, désolé et macabre, résonne du silence pesant des âmes perdues.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/locations/americas-newyork.webp" />
        <Image size="large" src="/images/universe/locations/americas-washington.webp" />
        <Image size="large" src="/images/universe/locations/americas-mexico.webp" />
      </Gallery>

      <Title>{`Présence humaine`}</Title>

      <Text>
        {`La vie humaine est ténue, dispersée en petits groupes de survivants qui se rassemblent comme des lucioles vacillantes dans l’obscurité de ces terres stériles. On croise quelques villages de fortune, des colonies éparses et des nomades arpentant les ruines de villes qui, par quelque miracle, ont échappé à l’anéantissement total. Dans cet environnement austère, les survivants s'accrochent les uns aux autres, unis par la nécessité d'affronter ensemble les défis incessants. Pourtant, le désespoir et la peur engendrent aussi leur lot d'ambitions, et certains tentent de s’élever au-dessus des autres, de s'arroger un semblant de pouvoir dans un monde où les lois de l’ancien temps n’existent plus.`}
      </Text>

      <Title>{`Présences non humaines`}</Title>

      <Text>
        {`Les Amériques sont une terre d’expérimentations macabres, des mutations hantant chaque recoin. Le virus aérien qui a engendré les Thogs, ces humains mutés, a eu des effets bien plus sombres et insidieux, transformant les terres en un laboratoire à ciel ouvert. Lorsque les bombes ont frappé, elles ont éventré d’innombrables installations militaires, libérant dans l’air des substances expérimentales dont nul ne connaît la vraie nature. Les êtres issus de ces aberrations biologiques, souvent difformes au point de défier toute classification, errent dans les terres dévastées. Ces créatures monstrueuses, croisement cauchemardesque entre la chair et la folie des hommes, incarnent la menace la plus immédiate et imprévisible pour les survivants, car elles ne ressemblent ni à des bêtes ni à des hommes : ce sont des reliques distordues d'un monde qui a perdu le contrôle de ses créations.`}
      </Text>
    </>
  );
}
