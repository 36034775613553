import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './AfterworldButton.module.scss';

export function AfterworldButton({
  children,
  ...rest
}) {
  return (
    <Button
      className={styles.button}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
}

AfterworldButton.propTypes = {
  children: PropTypes.node.isRequired,
};
