import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

export function SwipeHandler({
  children,
  onSwipeLeft = () => { },
  onSwipeRight = () => { },
}) {
  const handleSwipe = (direction) => {
    const isTextSelected = !!window.getSelection().toString();

    if (!isTextSelected) {
      if (direction === 'LEFT') {
        onSwipeLeft();
      } else if (direction === 'RIGHT') {
        onSwipeRight();
      }
    }
  };

  const handlers = useSwipeable({
    delta: { up: 500, down: 500, left: 150, right: 150 },
    // rotationAngle: -45,
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} style={{ minHeight: 'calc(100vh - 70px)' }}>
      {children}
    </div>
  );
}

SwipeHandler.propTypes = {
  children: PropTypes.node.isRequired,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
};
