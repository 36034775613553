import Box from '@mui/material/Box';

import { PageHeading } from 'components/elements';
import { SwipeBackToMenu } from 'layout/animations/SwipeBackToMenu';
import { PageRootContainer } from 'layout/PageRootContainer';

import styles from './NotFoundPage.module.css';

export function NotFoundPage() {
  return (
    <SwipeBackToMenu>
      <PageRootContainer>
        <PageHeading>
          Travaux
        </PageHeading>

        <Box className={styles.text}>
          Cette page est encore en travaux, desole !
        </Box>
      </PageRootContainer>
    </SwipeBackToMenu>
  );
}
