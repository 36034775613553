import { names } from 'data/character';

import { generator } from './common';

const _generatorName = (dataSet) => (
  [...new Set([generator(dataSet).name, generator(dataSet).name])]
);

export const generateNames = () => {
  return {
    male: {
      real: _generatorName(names.filter((name) => (name.gender === 'male' && name.universe === 'real'))),
      fantasy: _generatorName(names.filter((name) => (name.gender === 'male' && name.universe === 'fantasy'))),
    },
    female: {
      real: _generatorName(names.filter((name) => (name.gender === 'female' && name.universe === 'real'))),
      fantasy: _generatorName(names.filter((name) => (name.gender === 'female' && name.universe === 'fantasy'))),
    },
  };
};
