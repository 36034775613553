import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2px 0',
  '&:first-of-type': {
    marginTop: 0,
    paddingTop: 0,
  },
  '&:last-of-type': {
    marginBottom: 0,
    paddingBottom: 0,
  },
  '&:not(:first-of-type)': {
    borderTop: 'dotted 1px lightgray',
  },
}));

export function WeaponData({
  ammo = '',
  bpa = '',
  damage = '',
  effects = '',
  load = '',
  range = '',
  zone = '',
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {damage && (
        <BoxStyled>
          <span>Dégâts</span>
          <span>{damage}</span>
        </BoxStyled>
      )}

      {ammo && (
        <BoxStyled>
          <span>Type de munition</span>
          <span>{ammo}</span>
        </BoxStyled>
      )}

      {bpa && (
        <BoxStyled>
          <span>Nombre de balles tirées par action (BPA)</span>
          <span>{bpa}</span>
        </BoxStyled>
      )}

      {load && (
        <BoxStyled>
          <span>Capacité du chargeur</span>
          <span>{load}</span>
        </BoxStyled>
      )}

      {range && (
        <BoxStyled>
          <span>Portée</span>
          <span>{range}</span>
        </BoxStyled>
      )}

      {zone && (
        <BoxStyled>
          <span>Zone d&apos;effet</span>
          <span>{zone}</span>
        </BoxStyled>
      )}

      {effects && (
        <BoxStyled>
          {effects}
        </BoxStyled>
      )}
    </Box>
  );
}

WeaponData.propTypes = {
  ammo: PropTypes.string,
  bpa: PropTypes.string,
  damage: PropTypes.string,
  effects: PropTypes.string,
  load: PropTypes.string,
  name: PropTypes.string.isRequired,
  range: PropTypes.string,
  value: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
  zone: PropTypes.string,
};
