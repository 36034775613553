import { names } from 'data/character';

import { Magic } from './Magic';
import { Races } from './Race';
import { Skill } from './Skill';
// import { GiftSlot } from './gifts';
// import { Passive } from './passives';

export const SleepValues = {
  HEAVY: 'Lourd',
  LIGHT: 'Léger',
  NORMAL: 'Normal',
}

export const HandValues = {
  AMBIMANE: 'Ambimane',
  LEFT: 'Gauche',
  RIGHT: 'Droite',
}

export class Character {

  #id;
  get id() { return this.#id; }

  #name;
  get name() { return this.#name; }
  set name(v) { this.#name = String(v); }

  #level;
  get level() { return this.#level; }
  set level(v) { this.#level = Math.max(Math.min(parseInt(v, 10), 20), 1); }

  #race;
  get race() { return this.#race; }
  set race(v) { this.#race = Races[v]; }

  #skills;
  get skills() { return this.#skills; }

  #magic;
  get magic() { return this.#magic; }

  #pointsLuck;
  get pointsLuck() { return this.#pointsLuck; }
  set pointsLuck(v) { this.#pointsLuck = parseInt(v, 10); }

  #pointsDivine;
  get pointsDivine() { return this.#pointsDivine; }
  set pointsDivine(v) { this.#pointsDivine = parseInt(v, 10); }

  #pointsPsychose;
  get pointsPsychose() { return this.#pointsPsychose; }
  set pointsPsychose(v) { this.#pointsPsychose = parseInt(v, 10); }

  #pointsFatigue;
  get pointsFatigue() { return this.#pointsFatigue; }
  set pointsFatigue(v) { this.#pointsFatigue = parseInt(v, 10); }

  #sleep;
  get sleep() { return this.#sleep; }
  set sleep(v) { this.#sleep = SleepValues[v] || SleepValues.NORMAL; }

  #mainHand;
  get mainHand() { return this.#mainHand; }
  set mainHand(v) { this.#mainHand = HandValues[v] || HandValues.RIGHT; }

  #descriptionPhysical;
  get descriptionPhysical() { return this.#descriptionPhysical; }
  set descriptionPhysical(v) { this.#descriptionPhysical = `${v}`; }

  #descriptionPsychological;
  get descriptionPsychological() { return this.#descriptionPsychological; }
  set descriptionPsychological(v) { this.#descriptionPsychological = `${v}`; }

  #objectiveShortTerm;
  get objectiveShortTerm() { return this.#objectiveShortTerm; }
  set objectiveShortTerm(v) { this.#objectiveShortTerm = `${v}`; }

  #objectiveLongTerm;
  get objectiveLongTerm() { return this.#objectiveLongTerm; }
  set objectiveLongTerm(v) { this.#objectiveLongTerm = `${v}`; }

  #madnesses;
  get madnesses() { return this.#madnesses; }
  set madnesses(v) { this.#madnesses = `${v}`; }

  constructor() {
    if (!this.#id) {
      this.#id = crypto.randomUUID();
    }
    if (!this.#name) {
      this.name = names[Math.floor(Math.random() * names.length)].name;
    }
    this.level = 1;
    const racesKeys = Object.keys(Races);
    this.race = Races[racesKeys[Math.floor(Math.random() * racesKeys.length)]].name.toUpperCase();
    this.#skills = Skill.createFullSet(this);
    this.#magic = Magic.createFullSet(this);
    this.pointsLuck = 5;
    this.pointsDivine = 0;
    this.pointsPsychose = 0;
    this.pointsFatigue = 0;
    this.sleep = SleepValues.NORMAL;
    this.mainHand = HandValues.RIGHT;
  }

  get effects() {
    return [
      ...this.race.effects,
    ];
  }

  get skillPointsAvailable() { return (this.level + 1) * 30; }
  get skillPointsSpent() { return this.skills.reduce((total, v) => (total + v.spent), 0); }

  get magicPointsAvailable() { return (this.level + 1) * 15; }
  get magicPointsSpent() { return this.magic.reduce((total, v) => (total + v.spent), 0); }

  get passivePointsAvailable() { return (this.level + 1) * 3; }
  // get passivePointsSpent() { return this.#passiveSet.reduce((total, v) => (total + v.spent), 0); }

  isLegal() {
    if (this.skillPointsSpent > this.skillPointsAvailable) {
      throw new Error(`Trop de points de compétences dépensés.`);
    }

    if (this.magicPointsSpent > this.magicPointsAvailable) {
      throw new Error(`Trop de points de magie dépensés.`);
    }

    // if (this.passivePointsSpent > this.passivePointsAvailable) {
    //   throw new Error(`Trop de points de passifs dépensés.`);
    // }

    return true;
  }

  serialize() {
    return {
      id: this.id,
      level: this.level,
      magic: this.magic.map((s) => s.serialize()),
      name: this.name,
      pointsDivine: this.pointsDivine,
      pointsFatigue: this.pointsFatigue,
      pointsLuck: this.pointsLuck,
      pointsPsychose: this.pointsPsychose,
      race: this.race.name,
      skills: this.skills.map((s) => s.serialize()),
      sleep: this.sleep,
      mainHand: this.mainHand,
      descriptionPhysical: this.descriptionPhysical,
      descriptionPsychological: this.descriptionPsychological,
      objectiveShortTerm: this.objectiveShortTerm,
      objectiveLongTerm: this.objectiveLongTerm,
      madnesses: this.madnesses,
    };
  }

  static deserialize(savedData) {
    const character = new Character();
    character.#id = savedData.id;
    character.name = savedData.name;
    character.level = savedData.level;
    character.race = savedData.race.toUpperCase();
    character.#skills = savedData.skills.map((v) => Skill.deserialize(character, v));
    character.#magic = savedData.magic.map((v) => Magic.deserialize(character, v));
    character.pointsDivine = savedData.pointsDivine;
    character.pointsFatigue = savedData.pointsFatigue;
    character.pointsLuck = savedData.pointsLuck;
    character.pointsPsychose = savedData.pointsPsychose;
    character.sleep = this.sleep || '';
    character.mainHand = this.mainHand || '';
    character.descriptionPhysical = this.descriptionPhysical || '';
    character.descriptionPsychological = this.descriptionPsychological || '';
    character.objectiveShortTerm = this.objectiveShortTerm || '';
    character.objectiveLongTerm = this.objectiveLongTerm || '';
    character.madnesses = this.madnesses || '';
    return character;
  }
}
