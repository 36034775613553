
import { Subtitle, Text, Title, Quote } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualGlossaryPage() {
  return (
    <>
      <PageHeading>
        Glossaire
      </PageHeading>

      <Title>Degré de réussite</Title>

      <Quote>
        {`Le degré de réussite s'abrège avec DR`}
      </Quote>

      <Subtitle>Degré de réussite naturel ou DRN</Subtitle>

      <Text>
        {`Lors d'un test de compétence, le degré de réussite s'obtient en comparant le score du dé et le score de la compétence. La différence des dizaines correspond au degré de réussite.`}
      </Text>

      <Text>
        {`Par exemple, lors d'un tir avec un Fusil Sniper avec une compétence en Armes légères à 85, le score obtenu sur le dé est 32. Le degré de réussite est de 5 : 8 - 3.`}
      </Text>

      <Subtitle>Degré de réussite modifié ou DRM</Subtitle>

      <Text>
        {`Le degré de réussite naturel ne prend pas en compte les bonus ou malus, il s'agit de comparer uniquement le score du dé et la compétence. En tenant compte de ces bonus et malus, il s'agit désormais du degré de réussite modifié.`}
      </Text>

      <Text>
        {`Par exemple, lors d'un tir avec un Fusil Sniper avec une compétence en Armes légères à 85, le tireur est en hauteur et a un bonus de +10% mais la cible est mobile ce qui amène un malus de -30%, le seuil de réussite est donc de 65%, le score obtenu sur le dé est 31. Le degré de réussite est de 3 : 6 - 3.`}
      </Text>
    </>
  );
}
