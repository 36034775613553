import { BrowserRouter } from 'react-router-dom';

import { PageRoutes } from './PageRoutes';
import { MenuRoutes } from './MenuRoutes';

export function AppRouter() {
  return (
    <BrowserRouter>
      <PageRoutes />
      <MenuRoutes />
    </BrowserRouter>
  );
}
