
import alternativeCastingData from 'data/rules/alternativeCasting';
import penaltiesData from 'data/rules/penaltiesCasting';
import schoolsPrerequisites from 'data/rules/schoolsPrerequisites';
import { PageHeading } from 'components/elements';
import { Quote, Subtitle, Table, Text, Title } from 'components/semantic';

export function ManualMagicPage() {
  return (
    <>
      <PageHeading>
        Variantes
      </PageHeading>

      <Title>Lancer un sort</Title>

      <Subtitle>Incanter un sort</Subtitle>

      <Text>
        {`Incanter un sort nécessite une gestuelle physique et une messe verbale et cela n'est généralement pas discret.`}
      </Text>

      <Text>
        {`Incanter un sort est une action pleine et il n'est donc pas possible de faire d'autres actions en même temps lors d'un combat.`}
      </Text>

      <Text>
        {`Les sorts prennent environ 5 secondes à être lancés. Lors d'un combat, les sorts se lancent une fois que chaque participant a terminé son action, avant de passer au tour suivant.`}
      </Text>

      <Text>
        {`Incanter un sort demande une certaine concentration. Si le personnage est dérangé pendant l'incantation, il faut faire un test de Concentration pour maintenir l'incantation. Des malus peuvent être appliqués selon la nature du dérangement.`}
      </Text>

      <Subtitle>Le score de Magie</Subtitle>

      <Text>
        {`Un personnage dispose d'un score de Magie, c'est sa capacité à endurer l'utilisation répétée de sorts. La quantité de magie demandée par chaque sort lui est propre.`}
      </Text>

      <Quote>
        {`Si un sort n'arrive pas à terme, ce dernier ne coûtera que la moitié de la Magie prévue`}
      </Quote>

      <Text>
        {`Diverses réductions de coûts d'un sorts peuvent être accumulées, mais cela ne peut pas réduire le coût en dessous de 1.`}
      </Text>

      <Title>Sorts particuliers</Title>

      <Subtitle>Canaliser un sort</Subtitle>

      <Text>
        {`La plupart des sorts sont éphémères, et si l'on souhaite maintenir l'effet, ils doivent être canalisés. Canaliser un sort ne demande aucun jet de dé : il suffit de le décider après un sort lancé avec succès.`}
      </Text>

      <Text>
        {`Toutefois, cette action n'est pas sans coût. Un sort canalisé va consommer la moitié de son coût en Magie à chaque tour de canalisation.`}
      </Text>

      <Quote>
        {`Si un sort coûte 8 de Magie, le canaliser coûtera 4 de Magie par tour`}
      </Quote>

      <Text>
        {`Tout comme une incantation, canaliser un sort est une action : le personnage ne peut pas faire d'autres actions en même temps.`}
      </Text>

      <Text>
        {`Une exception existe toutefois : lancer un autre sort en parallèle. Il faut toutefois penser à vérifier la table des pénalités ! Incanter un sort alors qu'une canalisation est en cours apporte un malus de 25% sur les chances de succès du nouveau sort.`}
      </Text>

      <Subtitle>Les invocations</Subtitle>

      <Text>
        {`Une invocation est un sort spécial. Son coût en Magie n'est jamais récupéré tant que l'invocation est présente, il faut donc la désinvoquer pour récupérer la Magie associée.`}
      </Text>

      <Text>
        {`Certains sorts d'invocation sont canalisés, notamment s'il s'agit d'invocations qui peuvent s'animer. Les invocations ne peuvent pas se mouvoir d'elles-même.`}
      </Text>

      <Subtitle>La désinvocation</Subtitle>

      <Text>
        {`Désinvoquer est un procédé précis. Pour cesser une invocation, le personnage doit être à proximité de l'invocation et la désinvoquer, ce qui est une action rapide. Les points de Magie bloqués par l'invocation pourront être récupérés.`}
      </Text>

      <Text>
        {`Il est possible de  désinvoquer un sort en urgence, à distance, ou subir une désinvocation forcée. Parmi les conséquences attendues : perdre encore des points de Magie supplémentaires, voir les perdre définitivement, selon la situation.`}
      </Text>

      <Title>Actions</Title>

      <Subtitle>Meditation</Subtitle>

      <Text>
        {`Afin de récupérer rapidement des points de Magie, un personnage peut effectuer une méditation. Cette action dure un tour soit environ 5 secondes, coûte un point de Mental et permet de récupérer 5 points de Magie.`}
      </Text>

      <Text>
        {`Attention toutefois, une méditation nécessite du calme dans les alentours. Si l'environnement est agité, il faut réussir un test de Concentration pour effectuer une méditation, avec des malus possibles selon le vacarme.`}
      </Text>

      <Subtitle>Maintien de sort</Subtitle>

      <Text>
        {`Un sort incanté est lancé immédiatement après l'incantation. Toutefois il est possible de décider de le garder en "réserve" afin de lancer le sort plus tard.`}
      </Text>

      <Text>
        {`Tout comme pour la canalisation, maintenir un sort consomme la moitié du coût du sort en point de magie par tour de maintien.`}
      </Text>

      <Text>
        {`Si le sort n'est pas lancé à terme, il coûtera tout de même la moitié de son coût en points de Magie.`}
      </Text>

      <Quote>
        {`En cas de vacarme environnant, il faut réussir un test de Concentration pour continuer à maintenir un sort`}
      </Quote>

      <Quote>
        {`Il n'est pas possible de maintenir plus d'un sort à la fois ni de lancer d'autres sorts tant qu'il y a un maintien`}
      </Quote>

      <Title>Melanger les magies</Title>

      <Text>
        {`Certains sorts appartiennent à plusieurs écoles de magie en même temps, il existe plusieurs solutions pour les lancer.`}
      </Text>

      <Text>
        {`Soit le personnage maîtrise toutes les écoles du sort et peut le lancer de lui même, dans ce cas il faut faire le test dans l'école de magie avec le plus faible score.`}
      </Text>

      <Text>
        {`Les chances de succès sont également réduites de 10% par école de magie demandée par le sort.`}
      </Text>

      <Text>
        {`Soit il est possible de lancer un sort à plusieurs, chaque joueur décide alors avec quelle école de magie il veut participer au sort. Si un joueur décide de participer avec plusieurs écoles de magie, il faut se référer aux règles juste au-dessus pour son jet de dé.`}
      </Text>

      <Text>
        {`Chaque joueur aura un malus de 5% par joueur qui incante sur le sort.`}
      </Text>

      <Title>Variantes d&apos;incantation</Title>

      <Text>
        {`Lors de l'incantation d'un sort, certaines variantes peuvent être appliquées. Il est possible de lancer le sort plus rapidement, ou encore d'agrandir sa zone d'effet. Chacune de ces modifications sur le sort a un impact sur son coût.`}
      </Text>

      <Table data={alternativeCastingData}></Table>

      <Title>Penalites</Title>

      <Text>
        {`Selon les circonstances d'incantation, certaines pénalités sont prises en compte. Ces dernières impactent directement les chances de réussir un sort.`}
      </Text>

      <Table data={penaltiesData}></Table>

      <Title>Ecoles avancées</Title>

      <Text>
        {`Des écoles de magie avancées nécessitent que le personnage ait un niveau minimum avant de pouvoir s'y lancer et elles ne peuvent pas être choisies comme magies de spécialité.`}
      </Text>

      <Table data={schoolsPrerequisites}></Table>
    </>
  );
}
