import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    attacks: [`Applique Radiations III`],
    damage: [`4 + 1d6`, ``, ``],
    death: [`Explosion de radiations : applique Radiations IV aux personnes proches`],
    hit: [70, 65, 50],
    name: `Centaure`,
    speed: 1,
  },
  {
    attacks: [
      `50 % : transmets la Putride`,
      `25 % : transmets la Peste bubonique`,
    ],
    damage: [`4 + 1d4`, ``, ``],
    hit: [75, 0, 0],
    name: `Naufragé putride`,
    speed: 2,
  },
  {
    attacks: [`Applique Saignements II permanent`],
    damage: [`6 + 1d4`, ``, ``],
    hit: [65, 0, 0],
    initiative: 2,
    name: `Mangeur de chair`,
    speed: 2,
  },
  {
    abilities: [`Contrôle mental : se place sur la tête d'une cible et envahit son cerveau afin de diriger ses actions`],
    attacks: [`Applique Radiations IV`],
    hit: [85, 0, 0],
    initiative: 4,
    name: `Parasite`,
    speed: 4,
  },
  {
    armor: 4,
    attacks: [`Applique Radiations II`],
    damage: [`8 + DRN + 1d6`, ``, ``],
    hit: [70, 0, 0],
    name: `Fangetue`,
    passives: [`Pas d'armure à la tête`],
    speed: 2,
  },
  {
    abilities: [`Étranglement : maintient la cible et tente de l'étouffer. Si elle ne parvient pas à se libérer avant de perdre son souffle, elle tombe inconsciente`],
    armor: 1,
    damage: [`8 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    name: `Visionnaire`,
    passives: [
      `Champ de vision large : impossible d'être pris par surprise`,
      `Vision lointaine : capable de voir extrêmement loin et peut maintenir un pistage à la vue sur des kilomètres`,
    ],
    speed: 2,
  },
  {
    armor: 1,
    attacks: [
      `Applique Radiations IV`,
      `80% : transmets le Petit Mal`,
      `70% : applique Poison D+ pendant 6 tours`,
      `50% : transmets la Putride`,
      `25% : transmets la Peste bubonique`,
    ],
    damage: [`5 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 2,
    name: `Porteur de peste`,
    speed: 3,
  },
  {
    abilities: [`Crachat toxique : crache un bézoard purulent vers une cible avec 25% de chances de l'atteindre, transmets un cocktail de 3 maladies`],
    attacks: [
      `Applique Saignements I permanent`,
      `Applique Poison D permanent`,
    ],
    damage: [`6 + 1d8`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    name: `Abysse putride`,
    speed: 3,
  },
  {
    armor: 1,
    attacks: [`Applique Saignement II pour 3 tours`],
    damage: [`6 + 1d6`, `6 + 1d4`, ``],
    hit: [85, 65, 0],
    initiative: 3,
    magic: [
      { school: Spells.School.NATURE_WATER, level: `80 %` },
      { school: Spells.School.FIRE_EARTH, level: `60 %` },
      { school: Spells.School.SHADOW_DEATH, level: `40 %` },
    ],
    mana: `20`,
    name: `Goule des bois`,
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.HurlementStrident],
    armor: 2,
    attacks: [`Applique Radiations II`],
    damage: [`4 + 1d8`, ``, ``],
    hit: [80, 0, 0],
    name: `Mutant sylvestre`,
    speed: 3,
  },
  {
    armor: 2,
    attacks: [`Applique Saignements II permanent`],
    damage: [`8 + DRN + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    magic: [{ school: Spells.School.NATURE_WATER, level: `90 %` }],
    mana: `15`,
    name: `Goule du désert`,
    speed: 3,
  },
  {
    armor: 2,
    attacks: [`(PC) 80 % : renverse la cible au sol`],
    hit: [85, 60, 25],
    initiative: 2,
    items: [
      `Arme lourde simple ou exotique`,
      `Armure de cuir partielle`
    ],
    name: `Thog sauvage`,
    speed: 4,
    skills: [
      { skill: `Intimidation`, value: `65 %` }
    ],
  },
  {
    armor: 3,
    attacks: [
      `Applique Radiations III`,
      `(PM) 35 % : paralyse pendant 2 tours`
    ],
    damage: [`7 + 1d6`, ``, ``],
    hit: [85, 65, 0],
    initiative: 2,
    name: `Roi Fangetue`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 5 Fangetue [1 + 1d4]`,
      Monsters.Skills.Chef('Fangetue', 2),
    ],
    speed: 2,
  },
  {
    abilities: [`Explosion de radiations : applique Radiations V aux personnes proches`],
    armor: 1,
    attacks: [`Applique Radiations IV`],
    damage: [`3 + 1d6`, ``, ``],
    hit: [95, 0, 0],
    initiative: 5,
    name: `Irradié`,
    passives: [`Zone radioactive : les alentours appliquent Radiations III à la fin de chaque tour même après le décès`],
    speed: 3,
  },
  {
    armor: 3,
    attacks: [`Renverse la cible au sol`],
    damage: [`3 + 1d10`, ``, ``],
    hit: [65, 95, 0],
    initiative: 2,
    name: `Gomutant`,
    speed: 3,
  },
  {
    abilities: [`Feindre la mort : redevient conscient au bout de 2 tours après chaque décès non-létal`],
    armor: 1,
    damage: [`9 + 1d6`, ``, ``],
    hit: [85, 0, 0],
    initiative: 4,
    name: `Mutant XE-12`,
    speed: 4,
  },
  {
    abilities: [
      `Couvée : frappe une cible d'estoc et pond une larve dans son corps si l'attaque touche`,
      `Explosion de larves : provoque l'explosion d'une ou plusieurs larves proches, ce qui inflige 10 + 1d10 blessures et applique Saignements d'un rang de 1 + 1d4`
    ],
    attacks: [
      `Touche toutes les cibles sur 180° dans la direction attaquée (frappe de taille)`,
      `80 % : renverse au sol`
    ],
    damage: [`7 + 1d6`, ``, ``],
    hit: [75, 0, 0],
    name: `Couveuse`,
    passives: [`Récupération radioactive : soigne une blessure à la fin de chaque tour`],
    speed: 2,
  },
  {
    attacks: [
      `Rend fou pendant 1 heure`,
      `Gagne un point de folie`
    ],
    initiative: 2,
    name: `Mange-esprit`,
    passives: [Monsters.Skills.Group(`4 à 9 [3 + 1d6]`)],
    speed: 3,
  },
  {
    armor: 2,
    attacks: [
      `80% : applique Saignements II permanent`,
      `60% : rend fou pendant 120 tours (10 minutes)`,
      `40% : gagne un point de folie`,
      `20% : transmets la Putride`,
    ],
    damage: [`9 + 1d4`, ``, ``],
    hit: [90, 0, 0],
    initiative: 2,
    name: `Horreur des profondeurs fanatique`,
    speed: 2,
    show: [
      `Gagne un point de folie`,
      `60 % : rend fou pendant 120 tours (10 minutes)`,
    ],
  },
  {
    armor: 2,
    attacks: [`Applique Radiations V`],
    damage: [`4 + 1d8`, ``, ``],
    death: [`L'aura radioactive continue à faire effet`],
    hit: [90, 0, 0],
    initiative: 4,
    name: `Avatar de la radioactivité`,
    passives: [
      Monsters.Skills.RégénérationRadioactive,
      Monsters.Skills.AuraRadioactive('V'),
    ],
    show: [`40% : effraie les personnes proches`],
    speed: 3,
  },
  {
    abilities: [`Vol : vole dans le ciel en haute altitude, difficile à détecter`],
    death: [`Pluie radioactive : applique Radiations V à la fin de chaque tour pendant 3 tours`],
    name: `Flotteur irradié`,
    passives: [Monsters.Skills.AuraRadioactive('III')],
    speed: 4,
  },
  {
    armor: 3,
    attacks: [
      `Applique Poison D+ pour 30 tours`,
      `Les attaques PM ignorent l'armure`,
    ],
    damage: [`10 + 1d6`, ``, ``],
    hit: [95, 85, 0],
    initiative: 4,
    name: `Reine mut-fourmi`,
    passives: [`Gestation : peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Mut-fourmi géante », ne fonctionne qu'une seule fois`],
    speed: 3,
  },
  {
    armor: 6,
    attacks: [
      `Réduit l'Initiative de la cible de 1 pendant 6 tours`,
      `Applique Radiations V et laisse une flaque au sol qui applique Radiations III par tour pendant 12 tours`
    ],
    damage: [`9 + 1d6`, ``, ``],
    death: [`Provoque une explosion de gelée qui applique Radiations V aux individus dans un rayon de 3 mètres`],
    hit: [110, 90, 75],
    initiative: 4,
    name: `Roi des centaures`,
    passives: [
      `Groupe: se trouve généralement avec un groupe de 4 à 9 Centaures [3 + 1d6]`,
      Monsters.Skills.Chef('Centaures', 6),
    ],
    speed: 2,
  },
  {
    armor: 2,
    attacks: [`Applique Radiations III`],
    damage: [`7 + 1d6`, ``, ``],
    hit: [110, 0, 0],
    initiative: 4,
    name: `Goule cellulaire`,
    passives: [
      `Récupération radioactive: soigne une blessure à la fin de chaque tour`,
      `Gestation: peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Parasite », ne fonctionne qu'une seule fois`
    ],
    speed: 4,
  },
  {
    abilities: [`Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`],
    attacks: [`Applique Saignements IV permanent`],
    damage: [`7 + DRN + 1d4`, ``, ``],
    hit: [115, 0, 0],
    initiative: 5,
    name: `Mange-mort`,
    passives: [`Armure dans le dos : 5`],
    speed: 5,
  },
  {
    attacks: [`Applique Saignements V permanent`],
    damage: [`13 + DRN + 1d4`, ``, ``],
    hit: [110, 0, 0],
    initiative: 8,
    name: `Déchiqueteur`,
    show: [`Gagne un point de folie`],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Tremblement],
    armor: 6,
    damage: [`13 + 1d10`, ``, ``],
    hit: [85, 40, 0],
    items: [
      `Grosse arme quelconque de fortune`,
      `Grosse armure de fortune partielle ou complète`
    ],
    name: `Béhémoth Thog sauvage`,
    show: [`Effraie les individus proches`],
    speed: 5,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.MUTANT],
}));
