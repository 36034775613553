
import { Quote, Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualCharacterPage() {
  return (
    <>
      <PageHeading>
        Le personnage
      </PageHeading>

      <Title>La création</Title>

      <Subtitle>Les compétences</Subtitle>

      <Text>
        {`Chaque compétence dispose automatiquement d'un score de base de 20.`}
      </Text>

      <Text>
        {`Il faut ensuite répartir 60 points parmi ces compétences.`}
      </Text>

      <Quote>
        {`Attention, le nombre de points qu'il est possible de dépenser par compétence est limité à 5x le niveau du personnage`}
      </Quote>

      <Text>
        {`Enfin, il faut choisir trois compétences spécialisées qui auront leur score augmenté de 30 points supplémentaires.`}
      </Text>

      <Quote>
        {`La limite de points dépensés n'est pas effective pour les compétences spécialisées`}
      </Quote>

      <Subtitle>La magie</Subtitle>

      <Text>
        {`Tout comme pour les compétences, il faut répartir 30 points parmi les écoles de magie de base et choisir une magie spécialisée qui aura son score augmenté de 30 points supplémentaires.`}
      </Text>

      <Subtitle>Les aptitudes</Subtitle>

      <Text>
        {`Il est possible de choisir 2 aptitudes dans la liste des aptitudes de niveau 1.`}
      </Text>

      <Subtitle>Les passifs</Subtitle>

      <Text>
        {`Les passifs sont un peu plus complexes à appréhender car il y a une différence entre le nombre de points qui y sont dépensés et le nombre de points réels.`}
      </Text>

      <Text>
        {`Le premier point dépensé augmente le passif de 1, mais pour augmenter ce même passif à 2, il faudra y dépenser 2 points, et ainsi de suite ...`}
      </Text>

      <Quote>
        {`Par exemple un personnage qui a 3 en Initiative devra dépenser 4 points dans ce passif pour passer le score à 4`}
      </Quote>

      <Text>
        {`Deux colonnes séparées sont présentes sur la fiche de personnage afin d'aider à différencier les deux score. La colonne "Dépen." représente le nombre de points dépensés alors que la colonne "Réel" représente le nombre de points qui en découlent.`}
      </Text>

      <Text>
        {`Tout comme pour les compétences, chaque passif a un score de base qui est de 3, sauf l'armure et l'initiative qui n'ont pas de score de base.`}
      </Text>

      <Text>
        {`Il est ensuite possible de dépenser 6 points supplémentaires dans les passifs.`}
      </Text>

      <Subtitle>Les détails</Subtitle>

      <Text>
        {`Les autres détails comme la race, la main habituelle, le repos et les descriptions cosmétiques sont au choix du joueur, sous réserve de validation par le maître du jeu.`}
      </Text>

      <Title>{`L'évolution`}</Title>

      <Subtitle>Monter de niveau</Subtitle>

      <Text>
        {`Il n'y a pas de mécanisme d'expérience défini, la montée de niveau est laissée à la discrétion du maître du jeu.`}
      </Text>

      <Text>
        {`Toutefois, certaines bonnes pratiques sont généralement de vigueur :`}
      </Text>

      <Text>
        {`La montée de niveau s'effectue au repos, au calme, lorsque le personnage se détend, qu'il est capable de se remettre en question et de tirer des conclusions des faits et gestes passés.`}
      </Text>

      <Quote>
        {`Plus le personnage progresse et plus les niveaux suivants seront difficiles à atteindre`}
      </Quote>

      <Text>
        {`Survivre à une embuscade, vaincre des adversaires ou prendre le pouvoir sans bain de sang sont des actions suffisantes pour gagner les premiers niveaux.`}
      </Text>

      <Text>
        {`Par contre les derniers niveaux demanderont des hauts faits plus prestigieux comme défaire une organisation malfaisante, créer une religion ou ouvrir un portail vers un autre monde, des actions avec un impact conséquent.`}
      </Text>

      <Quote>
        {`Le maître du jeu peut faire des oublis! Il est pertinent de noter les actions pour les mettre en valeur lors du choix de montée de niveau. Avouer ses méfaits peut également en diminuer l'opprobre !`}
      </Quote>

      <Text>
        {`Lors de l'incarnation du personnage, il est important d'adopter un bon « rôle-play ». Si le personnage est fidèle et bon, il ne laissera pas compagnon décédé sans sépulture.`}
      </Text>

      <Text>
        {`Le personnage n'est pas censé avoir le même savoir que le joueur, il ne doit pas agir selon les informations du joueur mais selon les siennes ! Différencier les deux est essentiel pour le « rôle-play ».`}
      </Text>

      <Subtitle>Les bonus</Subtitle>

      <Text>
        {`À chaque niveau, un personnage gagne de nouveau 30 points de compétences, 15 points de magie, 1 aptitude et 3 passifs à dépenser.`}
      </Text>

      <Quote>
        {`Il est possible de choisir deux aptitudes de niveau inférieur si les aptitudes du niveau du personnage ne conviennent pas.\nPar exemple, si un personnage au niveau 5 ne choisit pas d'aptitudes de niveau 5, il peut alors prendre deux aptitudes de niveau inférieur à la place`}
      </Quote>

      <Subtitle>{`L'expérience`}</Subtitle>

      <Text>
        {`Au-delà des points gagnés lors de chaque niveau, les compétences et les magies peuvent également évoluer d'elles-même avec de la pratique.`}
      </Text>

      <Text>
        {`Lors d'un test sur une compétence ou un sort, par exemple un test de ${CHARACTER.SKILLS.MASTERY.DETECTION} pour tenter de voir des prédateurs dans une forêt, il est possible d'augmenter le score de la compétence selon le résultat du dé.`}
      </Text>

      <Text>
        {`Si le score est un succès critique naturel, la compétence ou le sort augmente d'un demi-point. Le prochain succès critique naturel permettra de le compléter et d'avoir un point entier !`}
      </Text>

      <Text>
        {`Un échec critique naturel quant à lui augmentera immédiatement la compétence ou le sort d'un point. On apprend davantage de ses échecs !`}
      </Text>

      <Quote>
        {`Le MJ peut également octroyer des points bonus à la fin de chaque session selon l'utilisation de certaines compétences`}
      </Quote>
    </>
  );
}
