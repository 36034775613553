
import { Image, Text, Title, Quote } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualAfterworldIntroductionPage() {
  return (
    <>
      <PageHeading>
        Manuel
      </PageHeading>

      <Title>Préambule</Title>

      <Text>
        {`Vous marchez vers la ville la plus proche lorsque sur votre chemin se trouve un cadavre au sol. Dans ses mains, une petite note :
        « Ils étaient six, tous vêtus de haillons des terres désolées. Leur carriole était décorée de têtes réduites et ils traînaient trois hommes enchaînés par le cou. Je ne les remarquai que trop tard, ce pourquoi j'essayais de commercer au lieu de prendre la fuite. Ils me dérobèrent ma besace, me torturèrent et me laissèrent mourir ici. Si d'aventure un étranger lis ces notes, je vous en prie, prévenez ma famille de mon décès - Henry Aglem »`}
      </Text>

      <Image size="large" src="/images/manual/intro/raiders.webp" />

      <Text>
        {`Vous remettez la note à sa place. Pas de temps pour cela, il vous faut trouver des vivres. Et de l'eau. Surtout de l'eau.
        La couleur du ciel à l'est a changé, et vous savez qu'une tempête de sable se prépare. Le genre qui vous arrose d'une pluie de déchets radioactifs. C'est avec hâte que vous avancez vers la ville pour vous abriter.
        Une fois proche, vous cachez vos affaires et mettez votre capuche. Vous savez qu'il faut se faire discret : ne pas attirer l'attention vous évitera des ennuis inutiles.`}
      </Text>

      <Image size="large" src="/images/manual/intro/travelling_man.webp" />

      <Text>
        {`Vous trouvez une taverne et y louez une chambre pour la nuit, petit moment calme lors duquel vous déciderez quelle sera votre prochaine destination.`}
      </Text>

      <Title>Le post-apocalyptique</Title>

      <Text>
        {`Votre histoire prend place dans un monde ravagé.
        Les causes de cette dévastation vous sont obscures. Des guerres nucléaires ont peut-être détruit la planète et les restes radioactifs ont rongé ce qu'il en restait ? Ou alors une maladie virale a contaminé l'espèce humaine et l'a mené à sa perte ? Si ce n'est un astéroïde qui s'est écrasé et a provoqué des changements climatiques ?
        Quoiqu'il en soit, vous n'avez pas le temps de vous poser ces questions : il vous faut survivre.`}
      </Text>

      <Quote>{`L'environnement est fort austère`}</Quote>

      <Image size="large" src="/images/manual/intro/paris.webp" />

      <Text>
        {`Le cadre est quelque peu hostile, la société est éradiquée et les relations sociales se font dans la crainte de son prochain.
        Nombreuses sont les raisons pour lesquelles un individu peut vous vouloir du mal : pouvoir, sexe, esclavagisme, fanatisme religieux. Et peu nombreuses sont celles qui le rendent amical. C'est dans ce contexte, sans l'influence de l'ancienne société, que les individus de ce monde interagissent entre eux.
        Au-delà de votre existence moribonde dans les vestiges de la civilisation passée, la nature tente aussi de poursuivre son œuvre. C'est ainsi que certaines formes de vie inconnues ont pu voir le jour. La magie est apparue. Nul ne sait d'où elle vient et nombreux sont les scientifiques qui tentent de résoudre cette équation dans l'espoir d'y voir un avenir pour l'humanité.
        C'est ainsi que ce manuel vous propose de vivre le jeu de rôle post-apocalyptique au plus profond de son essence, loin du monde d'antan dont les récits ne semblent être plus qu'une utopie, une vieille légende.`}
      </Text>

      <Title>Afterworld</Title>

      <Text>
        {`L'univers de ce jeu regorge de possibilités, davantage de descriptions sont disponibles directement dans la partie "Univers" de l'application car cela ne concerne pas les règles du manuel.`}
      </Text>

      <Text>
        {`Attention en consultant ces informations, bien que connues d'un joueur, cela ne signifie pas que le personnage joué par ce joueur est censé avoir ces informations.`}
      </Text>
    </>
  );
}
