import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import replaceAccentedCharacters from 'lib/normalizeString';

export function ButtonMenu({
  button,
  menuItems,
}) {
  const navigate = useNavigate();

  const [anchor, setAnchor] = useState(false);
  const menuOpened = Boolean(anchor);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const onClose = () => {
    if (menuOpened) {
      setAnchor(null);
    }
  };

  const navigateTo = (destination) => {
    navigate(destination);
    onClose();
  };

  return (
    <>
      <Button
        endIcon={<FontAwesomeIcon icon={['fa', button.icon]} />}
        onClick={handleOpenMenu}
      >
        {replaceAccentedCharacters(button.text)}
      </Button>

      <Menu
        anchorEl={anchor}
        open={menuOpened}
        onClose={onClose}
        className="header__menu"
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.text} onClick={() => navigateTo(menuItem.url)}>
            <FontAwesomeIcon icon={['fa', menuItem.icon]} />
            {replaceAccentedCharacters(menuItem.text)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ButtonMenu.propTypes = {
  button: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
};
