import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

import { ScrollToTopButton } from 'components/ScrollToTopButton';
import { SwipeBackToMenu } from 'layout/animations/SwipeBackToMenu';

export function PageRootContainer({
  children,
  parentComponent = Container,
  swipeTarget = '',
}) {
  const ParentComponent = parentComponent;

  const childrenRender = swipeTarget ? (
    <SwipeBackToMenu target={swipeTarget}>
      {children}
    </SwipeBackToMenu>
  ) : children;

  return (
    <>
      <ParentComponent component="main">
        {childrenRender}
      </ParentComponent>
      <ScrollToTopButton />
    </>
  );
}

PageRootContainer.propTypes = {
  children: PropTypes.node.isRequired,
  parentComponent: PropTypes.elementType,
  swipeTarget: PropTypes.string,
};
