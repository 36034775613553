import { traits } from 'data/character';

import { generator } from './common';

export const generateTraits = () => (
  [...new Set([
    generator(traits.filter((filter) => (filter.aspect === 'Positive'))),
    generator(traits.filter((filter) => (filter.aspect === 'Negative'))),
    generator(traits.filter((filter) => (filter.aspect !== 'Positive' && filter.aspect !== 'Negative'))),
    generator(traits.filter((filter) => (filter.aspect === 'Positive'))),
    generator(traits.filter((filter) => (filter.aspect === 'Negative'))),
    generator(traits.filter((filter) => (filter.aspect !== 'Positive' && filter.aspect !== 'Negative'))),
    generator(traits.filter((filter) => (filter.aspect === 'Positive'))),
    generator(traits.filter((filter) => (filter.aspect === 'Negative'))),
    generator(traits.filter((filter) => (filter.aspect !== 'Positive' && filter.aspect !== 'Negative'))),
  ])]
);
