import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    damage: [``, ``, ``],
    hit: [0, 0, 0],
    name: `Danseur`,
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Être magique : façonne un sort à l'aide d'une danse`
    ],
    speed: 2,
    skills: [
      {
        skill: `Souplesse`,
        value: `120 %`
      }
    ],
  },
  {
    initiative: 4,
    magic: [{ school: Spells.School.WIND_THUNDER, level: `90 %` }],
    mana: `20`,
    name: `Manifestation tempétueuse`,
    passives: [Monsters.Skills.Intangible],
    speed: 3,
  },
  {
    magic: [{ school: Spells.School.WATER_NATURE, level: `80 %` }],
    mana: `15`,
    name: `Marée mouvante`,
    passives: [Monsters.Skills.Flasque],
    speed: 2,
  },
  {
    attacks: [`Applique Brûlure II pour 3 tours`],
    damage: [`4 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    initiative: 2,
    magic: [{ school: Spells.School.FIRE_EARTH, level: `100 %` }],
    mana: `25`,
    name: `Manifestation ardente`,
    passives: [Monsters.Skills.Intangible],
  },
  {
    armor: 5,
    attacks: [`Renverse au sol`],
    damage: [`8 + 1d6`, ``, ``],
    hit: [80, 0, 0],
    magic: [{ school: Spells.School.WATER_NATURE, level: `110 %` }],
    mana: `20`,
    name: `Golem de glace`,
  },
  {
    initiative: 2,
    magic: [{ school: Spells.School.SHADOW_DEATH, level: `110 %` }],
    mana: `30`,
    name: `Érudit vagabond`,
    speed: 3,
  },
  {
    armor: 6,
    damage: [`6 + 1d8`, ``, ``],
    hit: [75, 60, 0],
    magic: [
      { school: Spells.School.FIRE_EARTH, level: `90 %` },
      { school: Spells.School.WIND_THUNDER, level: `70 %` },
    ],
    mana: `30`,
    name: `Goliath`,
    speed: 3,
  },
  {
    armor: 4,
    attacks: [
      `50% : applique Radiations III`,
      `50% : applique Saignements III permanent`,
    ],
    damage: [`8 + 1d4`, ``, ``],
    hit: [90, 0, 0],
    magic: [{ school: Spells.School.WATER_NATURE, level: `90 %` }],
    mana: `25`,
    name: `Manifestation glaciale`,
    speed: 3,
  },
  {
    initiative: 5,
    magic: [
      { school: Spells.School.WIND_THUNDER, level: `110 %` },
      { school: Spells.School.WATER_NATURE, level: `90 %` },
      { school: Spells.School.MIND, level: `50 %` },
    ],
    mana: `40`,
    name: `Avatar de la tempête`,
    speed: 3,
    skills: [{ skill: `Maîtrise`, value: `110 %` }],
  },
  {
    attacks: [`Dématérialise la cible vers le plan des Ombres petit à petit, réduit sa Constitution de 1 pendant 12 tours, cumulable. Arrivé à 0, la cible n'existe plus dans ce plan`],
    hit: [110, 0, 0],
    initiative: 6,
    magic: [{ school: Spells.School.SHADOW_DEATH, level: `120 %` }],
    mana: `20`,
    name: `Ombre vivante`,
    passives: [Monsters.Skills.Intangible],
    show: [`70% : effraie les personnes proches`],
    speed: 6,
  },
  {
    damage: [`7 + 1d4`, ``, ``],
    hit: [100, 0, 0],
    initiative: 2,
    magic: [
      { school: Spells.School.SHADOW_DEATH, level: `120 %` },
      { school: Spells.School.MIND, level: `80 %` },
    ],
    mana: `30`,
    name: `Cauchemar`,
    passives: [`Horreur : à la fin de chaque tour, les personnes proches gagnent un point de psychose`],
    show: [`Effraie les personnes proches`],
    speed: 4,
  },
  {
    actions: `+1`,
    armor: 8,
    attacks: [`Applique Brûlure III pour 3 tours`],
    damage: [`12 + 1d8`, ``, ``],
    hit: [115, 0, 0],
    initiative: 2,
    magic: [
      { school: Spells.School.FIRE_EARTH, level: `110 %` },
      { school: Spells.School.WIND_THUNDER, level: `40 %` },
    ],
    mana: `50`,
    name: `Kobold`,
    speed: 5,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.ELEMENTAL],
}));
