const WEAPONS = Object.freeze({
  LIGHT: 'Armes légères',
  HEAVY: 'Armes lourdes',
  MELEE: 'Corps-à-corps',
  THROWING: 'Lancer, Pièges',
});

const KNOWLEDGE = Object.freeze({
  BIOLOGY: 'Biologie',
  CULTURE: 'Culture',
  ENGINEERING: 'Ingénierie',
  SURVIVAL: 'Survie',
});

const SOCIAL = Object.freeze({
  EMPATHY: 'Empathie',
  INTIMIDATION: 'Intimidation',
  NEGOTIATE: 'Négociation',
  TALKING: 'Rhétorique',
});

const DEFENSES = Object.freeze({
  FOCUS: 'Concentration',
  COLD_BLOOD: 'Sang froid',
  VIGOR: 'Vigueur',
  WILL: 'Volonté',
});

const MASTERY = Object.freeze({
  ACUITY: 'Acuité',
  AGILITY: 'Agilité',
  STRENGTH: 'Force',
  INTELLECT: 'Intelligence',
});

const SKILLS = Object.freeze({
  WEAPONS,
  KNOWLEDGE,
  SOCIAL,
  DEFENSES,
  MASTERY,
});

window.CHARACTER = Object.freeze({
  SKILLS,
});
