import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const adjustElementSizeToText = function (htmlElement) {
  htmlElement.style.letterSpacing = null;
  htmlElement.style.fontSize = null;

  let breakFlag = false;
  let contentWidth = 0;

  do {
    const currentStyle = getComputedStyle(htmlElement);
    const fontSize = parseInt(currentStyle.fontSize);
    const letterSpacing = parseInt(currentStyle.letterSpacing);

    if (letterSpacing > 1) {
      htmlElement.style.letterSpacing = (letterSpacing - 1) ? (letterSpacing - 1) + "px" : "normal";
    } else if (fontSize > 0) {
      htmlElement.style.fontSize = (fontSize - 1) + "px";
    } else {
      breakFlag = true;
    }

    contentWidth = htmlElement.clientWidth - parseFloat(currentStyle.paddingLeft) - parseFloat(currentStyle.paddingRight);
  } while (htmlElement.scrollWidth > contentWidth && !breakFlag);
};

export function AdaptativeText({ children }) {
  const titleReference = useRef(null);

  useEffect(() => {
    adjustElementSizeToText(titleReference.current);
  }, [children]);

  return (
    <div ref={titleReference}>
      {children}
    </div>
  );
}

AdaptativeText.propTypes = {
  children: PropTypes.string.isRequired,
};
