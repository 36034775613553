import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const InnerImage = styled.img`
  animation: image-pulsingShadow 2s infinite;
  border-radius: 12px;
  filter: drop-shadow(0px 0px 4px DarkSlateGray);
  display: block;
  margin: 8px auto;

  &.small { max-width: 33%; }
  &.medium { max-width: 50%; }
  &.large { max-width: 75%; }
  &.original { max-width: 100%; }

  @media (min-width: 720px) {
    &.small { max-width: 40%; }
    &.medium { max-width: 60%; }
    &.large { max-width: 80%; }
  }

  @media (min-width: 1024px) {
    &.small { max-width: 25%; }
    &.medium { max-width: 33%; }
    &.large { max-width: 50%; }
  }
`;

export function Image({
  size = 'original',
  src,
}) {
  const url = `${process.env.PUBLIC_URL}${src}`;

  return (
    <InnerImage className={size} src={url} alt={url} />
  );
}

Image.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'original']),
  src: PropTypes.string.isRequired,
};
