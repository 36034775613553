import Box from '@mui/material/Box';
import styled from "@emotion/styled";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;

  &:not(:last-of-type) {
    border-bottom: dotted 1px DarkSlateGray;
  }

  &.even {
    background-color: #F0F0F0;
  }

  &.odd {
    background-color: #FAFAFA;
  }
`;

export function CharacterSkillsTableRow({ isEven, skill }) {
  return (
    <Row className={`character_skills_row ${isEven ? 'even' : 'odd'}`}>
      <Box>{skill.name}</Box>
      <Box>{skill.value}</Box>
    </Row>
  );
}
