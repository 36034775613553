import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  }
};

export function Gallery({
  children,
}) {
  return (
    <Carousel
      containerClass="carousel-container"
      deviceType="desktop"
      dotListClass="custom-dot-list-style"
      draggable={false}
      infinite={true}
      keyBoardControl={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      responsive={responsive}
      showDots={true}
      swipeable={true}
      transitionDuration={1000}
    >
      {children}
    </Carousel>
  );
}

Gallery.propTypes = {
  children: PropTypes.node.isRequired,
};
