import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import ServiceWorkerContext from 'layout/service-worker/ServiceWorkerContext';

export function ServiceWorkerUpdateGuard({ children }) {
  const serviceWorkerContext = useContext(ServiceWorkerContext);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const onUpdateCallback = () => {
      setUpdateAvailable(true);
    };

    if (serviceWorkerContext.serviceWorker) {
      serviceWorkerContext.serviceWorker.addEventListener("updatefound", onUpdateCallback);
    }

    return () => {
      if (serviceWorkerContext.serviceWorker) {
        serviceWorkerContext.serviceWorker.removeEventListener("updatefound", onUpdateCallback);
      }
    };
  }, [serviceWorkerContext.serviceWorker, setUpdateAvailable]);

  if (updateAvailable) {
    const refreshPage = () => {
      if (window.confirm("Une nouvelle version est disponible. Rafraîchir la page ?")) {
        window.location.reload();
      }
    };

    return (
      <div className="update-notification">
        <p>Une nouvelle version est disponible !</p>
        <button onClick={refreshPage}>Rafraîchir</button>
      </div>
    );
  }

  return children;
}

ServiceWorkerUpdateGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
