import React from 'react';

import { Title } from 'components/semantic';
import replaceAccentedCharacters from 'lib/normalizeString';
import { CharacterSkillsTableRow } from 'components/character/CharacterSkillsTableRow';

export function CharacterSkillsTable({ data }) {
  const categories = data.reduce((output, skill) => {
    const categories = { ...output };
    if (!categories[skill.category]) {
      categories[skill.category] = [];
    }
    categories[skill.category].push(skill);
    return categories;
  }, {});

  return (Object.entries(categories).map(([categoryName, skills]) => (
    <React.Fragment key={categoryName}>
      <Title>
        {replaceAccentedCharacters(categoryName)}
      </Title>
      {skills.map((skill, idx) => (
        <CharacterSkillsTableRow key={skill.name} isEven={idx % 2} skill={skill} />
      ))}
    </React.Fragment>
  )));
}
