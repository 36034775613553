import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import './ArmorDataArea.scss';

export function ArmorDataArea({
  children,
  value = '',
}) {
  return (
    <Box className={`armor-data-area ${value ? 'full' : 'empty'}`}>
      <Box className="armor-data-area-name">
        {children}
      </Box>

      <Box className="armor-data-area-value">
        {value}
      </Box>
    </Box>
  );
}

ArmorDataArea.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
};
