
import { Table, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

const prices = {
  Biens: [
    ['Auberge - Repas conserve', '8'],
    ['Auberge - Repas cuisiné', '12'],
    ['Auberge - Soupe populaire', '4'],
    ['Feuille', '12'],
    ['Cheval', '500'],
  ],
  Services: [
    ['Nuit auberge', '8'],
    ['Nuit écurie', '5'],
    ['Journée-homme - Métier artisanat', '25'],
    ['Journée-homme - Métier sciences', '15'],
    ['Journée-homme - Métier social', '20'],
    ['Journée-homme - Métier violent', '40'],
  ]
};

export function UniversePricesPage() {
  return (
    <>
      <PageHeading>
        Les prix
      </PageHeading>

      <Title>{`Biens`}</Title>
      <Table data={prices.Biens} />

      <Title>{`Services`}</Title>
      <Table data={prices.Services} />
    </>
  );
}
