export const Spells = Object.freeze({
  Types: {
    CAST: 'Incantation',
    SUMMON: 'Invocation',
    CHANNEL: 'Canalisation',
    CAST_CHANNEL: 'Canalisable',
  },
  Requirement: {
    CONTACT: 'Contact',
    PROXIMITY: 'Proximité',
    COMPONENT: 'Composant',
    RELIC: 'Relique',
  },
  School: {
    WATER_NATURE: 'Eau et Nature',
    FIRE_EARTH: 'Feu et Terre',
    WIND_THUNDER: 'Vent et Foudre',
    LIGHT_LIFE: 'Lumière et Vie',
    SHADOW_DEATH: 'Ombre et Mort',
    MIND: 'Esprit',
    PLANS: 'Plans et Mutations',
    TIME: 'Temps',
    SAME: 'Même école',
    ALL: 'Toutes écoles',
  }
});
