import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { SwipeHandler } from './SwipeHandler';

export function SwipeBackToMenu({ children, target = '/' }) {
  const navigate = useNavigate();

  const handleSwipe = () => {
    navigate(target);
  }

  return (
    <SwipeHandler onSwipeRight={handleSwipe}>
      {children}
    </SwipeHandler>
  );
}

SwipeBackToMenu.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
};
