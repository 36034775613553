import { Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function UniversePeoplePage() {
  return (
    <>
      <PageHeading>
        {`Les gens`}
      </PageHeading>

      <Text>
        {`Voici un aperçu du commun des mortels dans le monde impitoyable d'Afterworld, où la survie est une lutte quotidienne.`}
      </Text>

      <Title>{`Apparence`}</Title>

      <Text>
        {`La majorité des habitants arbore un air farouche, leur peau est souvent marquée de cicatrices laissées par les épreuves passées. Poussiéreux et endurcis, ils portent des vêtements qui ont vu bien des jours, noircis par le temps, la crasse, et les épreuves. Ces habits sont rapiécés, parfois avec des matériaux de fortune, et jamais assortis, témoignant de leur passé de récupération ou de vol.`}
      </Text>

      <Text>
        {`Les cheveux sont rarement entretenus ; gras, emmêlés, ils tombent en mèches épaisses qui collent à leur crâne sale. Les barbes, pour ceux qui en ont, sont longues et désordonnées, souvent tressées avec des bouts de fil de fer ou de tissu, une esthétique de survie plus qu'un choix. Les ongles, longs et cassés, montrent les signes de labeurs incessants.`}
      </Text>

      <Text>
        {`Des odeurs puissantes accompagnent souvent les gens, une mélodie âcre de sueur rance, parfois masquée par un nuage d’alcool bon marché, autant de tentatives pour supporter les rigueurs de ce monde. Certains, mutilés par la dureté de la vie, arborent des mains aux doigts manquants, et il n'est pas rare de croiser des visages édentés, des mâchoires ornées de dents jaunes ou noires, témoins des maux délaissés et des lendemains incertains.`}
      </Text>

      <Title>{`Attitude`}</Title>

      <Text>
        {`Dans les rues et les places poussiéreuses, chaque regard est furtif et méfiant, toujours en alerte. Les gens savent que dans ce monde, un instant de faiblesse peut signer la fin. Le moindre bruit attire l’attention, une habitude acquise par ceux qui ont survécu à maintes menaces et trahisons. Loin des gestes amicaux, les regards échangés sont durs, calculateurs, et chacun garde ses distances.`}
      </Text>

      <Text>
        {`Les armes sont omniprésentes, que ce soit un simple couteau émoussé glissé dans la ceinture ou un vieux revolver couvert de rouille. Pourtant, elles ne sont brandies qu’en dernier recours, car chaque combat est un pari sur sa propre survie. Les blessures coûtent cher dans un monde où les soins sont rares, et chacun sait qu'une vie vaut bien plus qu’une querelle passagère.`}
      </Text>

      <Title>{`Connaissances`}</Title>

      <Text>
        {`Ce que l'on considère aujourd'hui comme des savoirs basiques est ici devenu rare et précieux. La plupart des gens n'ont jamais appris à lire ou écrire, et les mots écrits ne sont que des symboles sans signification pour eux. Nager, conduire un véhicule, comprendre une machine complexe sont des compétences oubliées, réservées à quelques rares survivants d’un autre temps ou aux sages qui ont su préserver une part de l'ancien savoir.`}
      </Text>
    </>
  );
}
