
import { Image, Quote, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualDrugsPage() {
  return (
    <>
      <PageHeading>
        Drogues
      </PageHeading>

      <Title>Consommer</Title>

      <Text>
        {`L'utilisation de drogue ou d'alcool peut provoquer des dépendances. Ces substances addictives possèdent chacune un score de dépendance.`}
      </Text>

      <Text>
        {`Si le score en Vigueur est supérieur à ce montant alors il n'y a pas de test à faire : il n'est pas possible d'être dépendant à cette substance.
Si ce n'est pas le cas, il faut faire un test de Volonté avec comme malus la différence entre le score de dépendance de la substance et la compétence en Volonté. En cas d'échec, le personnage devient dépendant.`}
      </Text>

      <Text>
        {`Les effets du manque commencent à se ressentir au bout de 24 heures après la dernière consommation. Ils vont s'intensifier toutes les 24 heures s'il n'y a pas consommation de la substance entre-temps.`}
      </Text>

      <Text>
        {`Après 5 jours de manque, le personnage tombe inconscient et gagne un point de Psychose. Le sevrage commence.`}
      </Text>

      <Text>
        {`Si un personnage atteint 3 points de dépendance à une substance, il est en overdose : il tombe inconscient, gagne un point de folie et commence un sevrage forcé.`}
      </Text>

      <Text>
        {`S'il advient que le personnage continue à être drogué pendant qu'il est inconscient jusqu'à atteindre 5 points de dépendance, il décède.`}
      </Text>

      <Image size="large" src="/images/manual/other/drugs.webp" />

      <Title>Le sevrage</Title>

      <Text>
        {`Pendant le sevrage, il faut effectuer un test de Volonté toutes les 6 heures.`}
      </Text>

      <Text>
        {`Sur 3 réussites, le personnage est sevré et perd définitivement 10 points dans toutes les compétences de Résistances.`}
      </Text>

      <Text>
        {`Sur 3 échecs consécutifs, le personnage décède.`}
      </Text>

      <Quote>
        {`Le sevrage de plusieurs dépendances à différentes substances est traité en parallèle pour chacune d'entre elles.`}
      </Quote>

      <Image size="large" src="/images/manual/other/weaning.webp" />
    </>
  );
}
