import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseLocationOceaniaPage() {
  return (
    <>
      <PageHeading>
        {`L'Océanie`}
      </PageHeading>

      <Title>{`Paysage`}</Title>

      <Text>
        {`L’Océanie, autrefois une terre d’îles et de vastes étendues océaniques, a connu sa chute bien avant la grande guerre, non par les bombes mais par les fruits mêmes de son avancée technologique. Le développement effréné des intelligences artificielles et des machines de toutes sortes a mené à une révolte silencieuse qui a renversé la civilisation humaine, laissant ses villes sombrer dans un silence mécanique. Aujourd’hui, les ruines des métropoles et des villages, envahies par la rouille et le lichen, sont peuplées de robots errants. Ces machines déambulent sans but apparent, guidées par des routines détraquées et des missions oubliées, créant un tableau étrange où la technologie révolue et la nature tentent de coexister dans un équilibre désespéré.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/locations/oceania-perth.webp" />
        <Image size="large" src="/images/universe/locations/oceania-dunedin.webp" />
        <Image size="large" src="/images/universe/locations/oceania-darwin.webp" />
      </Gallery>

      <Title>{`Présence humaine`}</Title>

      <Text>
        {`Les rares survivants humains vivent dans une crainte constante des machines, organisés en petits groupes qui se terrent dans les coins les plus reculés ou dissimulés de ces terres en ruines. La survie ici dépend d'une cohésion sans faille, chaque membre étant indispensable à la survie du groupe. La méfiance envers toute trace de technologie et l’esprit d’équipe sont essentiels pour se dissimuler des sentinelles robotiques qui rôdent inlassablement, détectant toute chaleur ou mouvement.`}
      </Text>

      <Title>{`Présences non humaines`}</Title>

      <Text>
        {`La faune sauvage et les mutants sont quasiment absents de cette région, leurs populations ayant été impitoyablement éradiquées par les robots au cours de leur sombre ascension. Ces machines, à l’intelligence rigide et impitoyable, exécutent des tâches autrefois programmées ou de nouvelles directives incompréhensibles. Aujourd’hui, elles représentent la menace la plus redoutable, surveillant le territoire et éliminant tout intrus. Ce sont les véritables maîtres de l’Océanie, et toute forme de vie qui croise leur chemin se retrouve confrontée à un danger implacable.`}
      </Text>
    </>
  );
}
