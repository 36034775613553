import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseLocationAsiaPage() {
  return (
    <>
      <PageHeading>
        {`L'Asie`}
      </PageHeading>

      <Title>{`Paysage`}</Title>

      <Text>
        {`L'Asie, autrefois terre de vastes mégapoles et de cultures millénaires, est désormais un paysage de contrastes frappants. Les grandes villes, jadis des centres de vie bouillonnants, n’ont pas échappé aux frappes stratégiques de l’ancien monde. Ce sont aujourd’hui des amas de ruines irradiées, des cratères béants ou des bâtiments fantomatiques, pillés et silencieux, où la nature a commencé à s’insinuer, recouvrant les structures de végétation dense et entêtante. Dans les campagnes, la vie semble encore plus vigoureuse. La végétation y est luxuriante, proliférant même sous une fine couche de neige, résultat du refroidissement climatique. Ce climat capricieux a transformé les étendues rurales en forêts étrangement calmes et en montagnes enneigées, créant une atmosphère presque mystique, où la vie sauvage règne en maître.`}
      </Text>

      <Gallery>
        <Image size="large" src="/images/universe/locations/asia-hongkong.webp" />
        <Image size="large" src="/images/universe/locations/asia-bangkok.webp" />
        <Image size="large" src="/images/universe/locations/asia-moscow.webp" />
      </Gallery>

      <Title>{`Présence humaine`}</Title>

      <Text>
        {`La présence humaine est fragile, comme un souffle suspendu dans ce vaste paysage. Les survivants, prudents, vivent en communautés discrètes, établissant de petits villages improvisés dans des recoins reculés ou au cœur de vallées dissimulées. Ils évitent les ruines des anciennes cités, marquées par la mort et la désolation, préférant la sécurité relative de la campagne. La confiance est rare, remplacée par une prudence quasi instinctive, mais une forme de solidarité persiste, subtile et précieuse, où chacun contribue à la survie de tous dans cet environnement incertain.`}
      </Text>

      <Title>{`Présences non humaines`}</Title>

      <Text>
        {`Outre quelques créatures mutantes qui hantent les lieux les plus isolés, le danger le plus insidieux en Asie prend une forme bien plus éthérée. Ici, la frontière entre le monde tangible et le spirituel semble fine, comme si les vents magiques soufflaient avec plus de force, et des manifestations surnaturelles se matérialisent régulièrement. Ces apparitions prennent parfois la forme d’élémentaires, des esprits incarnés dans l’eau, le vent ou le feu, qui patrouillent les terres, oscillant entre bienveillance distante et hostilité farouche. Ces êtres, reflets d’une spiritualité revenue à la vie, ne sont pas toujours bienveillants ; certains protègent leur territoire avec une fureur implacable, faisant des esprits et des élémentaires la plus grande menace pour les rares âmes qui arpentent ces terres imprégnées de mystère.`}
      </Text>
    </>
  );
}
